@font-face {
    font-family: 'Calibri';
    src: url('/public/fonts/Calibri.ttf');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-00FE;
}
@font-face {
    font-family: 'Calibri';
    src: url('/public/fonts/calibrib.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Calibri';
    src: url('/public/fonts/calibrii.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Calibri';
    src: url('/public/fonts/calibriz.ttf');
    font-weight: bold;
    font-style: italic;
}
/*FROM OLD ACCOUNT CENTER*/
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
.glyphicon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}

body {
    font-family: Calibri;
    background: #495060;
    color: #222;
    padding-right: 0 !important;
}

html,
body,
#app,
.container,
.container > div,
.container.body,
.col-md-3.left_col {
    height: 100%;
}

@media (min-width: 992px) {
    .container > div.main_container,
    .right_col {
        min-height: calc(100% - 72px);
    }

    .dashboard.right-container.timerStart {
        box-sizing: border-box;
        padding: 10px 25px 61px 25px;
    }
}

body,
.main_container,
.container.body,
.right_col > div,
.right-container {
    background: #f7f8fa;
}

.right-container {
    transition: 0.5;
}

input[type='file'],
input[type='radio'],
input[type='checkbox'] {
    &:focus {
        outline-offset: 0;
        outline: 0;
    }
}

div {
    white-space: normal;
}

p {
    margin: 0;
}

a,
a:hover,
a:focus,
a:active {
    color: #717171;
}

.question a,
.question a:hover,
.question a:focus,
.question a:active,
.question a:visited,
.blueLink a,
.blueLink a:hover,
.blueLink a:focus,
.blueLink a:active,
.blueLink a:visited {
    color: #337ab7;
}

.darkText a,
.darkText a:hover,
.darkText a:focus,
.darkText a:active,
.darkText a:visited {
    color: #222;
}

a.whiteText,
a.whiteText:hover,
a.whiteText:focus,
a.whiteText:active,
a.whiteText:visited {
    color: #fff;
}

a.link,
a.link:hover,
a.link:focus,
a.link:active,
a.link:visited,
.textLink,
.textLink:hover,
.textLink:focus,
.textLink:active,
.textLink:visited,
.btn-default.textLink:active:focus,
.editHover:hover {
    color: #1071bc;
}

.link,
.textLink,
.editHover {
    &:hover {
        cursor: pointer;
    }
}

.content img,
.panel-body img,
.modal-body img,
p img {
    max-width: 100%;
    height: auto;
}

.testPanel,
.eGallery-panel {
    p {
        img {
            max-width: 600px;
            max-height: 400px;
            object-fit: contain;
            cursor: pointer;
            width: 100%;
        }
    }

    .imgMax100 {
        img {
            max-width: 100%;
        }
    }
}

.acceptNewAnsModal,
.changeCorrectAnsModal,
.editQuestionStemModal {
    p {
        img {
            max-width: 100%;
        }
    }
}

/* sidebar */
.nav-sm .container.body .col-md-3.left_col {
    /* width: 110px; */
    width: 135px;
    padding: 0;
    z-index: 999;
    position: fixed;
}

/* end of sidebar */

/* border title */

.borderGrey {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #717171;
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.88rem;
    line-height: 1.2;
}

.borderBlack {
    border-radius: 3px;
    border: 1px solid #222;
    background: #fff;
    color: #222;
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.88rem;
    line-height: 1.2;
}

/* end of border title */

/*Modal CSS*/

#suspendModal .modal-body .row:last-child {
    margin-top: 30px;
}

#suspendModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#suspendModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
/* end of Modal */
.flex {
    display: flex;
    flex-wrap: wrap;
}

.inline-flex-wrap {
    display: inline-flex;
    flex-wrap: wrap;
}

.flexOnly {
    display: flex;
}

.flex1 {
    flex: 1;
}

.flexWrap {
    flex-wrap: wrap;
}

.overFlowAuto {
    overflow: auto;
}

.align-items {
    align-items: center;
}

.alignItemsNormal {
    align-items: normal;
}

.alignFlexStart {
    align-items: flex-start;
}

.alignFlexEnd {
    align-items: flex-end;
}

.alignBaseline {
    align-items: baseLine;
}

.alignSelfEnd {
    align-items: self-end;
}

.alignRight {
    text-align: right;
}

.textAlignLeft {
    text-align: left;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: end;
}

.justifyLeft {
    justify-content: left;
}

.flexRight {
    display: flex;
    justify-content: flex-end;
}

.flexRight div {
    justify-content: flex-end;
}

.flexSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.inlineFlex {
    display: inline-flex;
}

.displayContent {
    display: contents;
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

.capitalize {
    text-transform: capitalize;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    float: none;
    padding: 0;
}

/* Alert */

.alert {
    padding: 5px 10px;
    line-height: 1.2;
    margin-bottom: 0;

    .btn {
        margin-left: 0;
        padding: 2px 12px;

        &.btn-default {
            margin-left: inherit;
            padding: 6px 12px;
        }

        &.marginLeft20 {
            margin-left: 20px;
        }
    }
}

.alert-primary {
    background: #f6f8fa;
    border: 1px solid #0071be;
    color: #222;
    border-radius: 8px;
    display: flex;

    i {
        color: #0071be;
        font-size: 1rem;
    }

    i.greyText {
        color: #717171;
    }

    .btn {
        i {
            color: #fff;
        }

        &:hover {
            i {
                color: #222;
            }
        }
    }
}

.alert-primary-bg {
    background: rgba(15, 155, 129, 0.1);
    border: 2px solid rgba(15, 155, 129, 0.1);
    color: #222;
    border-radius: 8px;
    display: flex;
}

.alert-warning.alignItemsNormal {
    align-items: normal;
}

.alert-warning,
.alert-warning-new,
.alert-warning-bg,
.alert-warning-v2 {
    border-radius: 8px;
    color: #222;
    background: #fdefd8;
    border: 2px solid #8a5016;
    display: flex;

    i {
        color: #8a5016;
        font-size: 1.111rem;

        &.fontSize18 {
            font-size: 1rem;
            margin-top: 0;
        }

        &.marginTop0 {
            margin-top: 0;
        }
    }

    button {
        &.close {
            opacity: 1;
        }

        i {
            color: inherit;
            font-size: inherit;

            &.fontBlack {
                color: #222;
            }
        }
    }

    i.darkGreen {
        color: #3c7858;
    }

    i.greyText {
        color: #717171;
    }

    &.d-block {
        display: block;
    }

    p {
        font-size: 1rem;
    }
}

.alert-success {
    color: #3c7858;
    border: 2px solid #3c7858;
    border-radius: 8px;
    display: flex;
    background: #f0fffc;
    align-items: flex-start;
    font-size: 1rem;
    text-align: left;

    i {
        color: #3c7858;
        margin-right: 5px;
    }
}

.alert-danger-new,
.alert-danger {
    color: #a52a2a;
    border: 2px solid #a52a2a;
    border-radius: 8px;
    display: flex;
    background: #ffecec;
    align-items: flex-start;
    text-align: left;
    font-size: 1rem;

    i {
        color: #a52a2a;
        margin-right: 5px;
    }
}

.alert-info {
    color: #fff;
    display: flex;

    i {
        color: #fff;
        font-size: 12px;
        margin-right: 5px;
    }
    span {
        line-height: 1;
    }
}

@media (max-width: 991px) {
    .creation .content .alert .btn {
        width: auto;
        margin-top: 0;
    }
}
.alert-black {
    color: #fff;
    background-color: #434e5e;
    border-color: #434e5e;
    position: relative;
    font-size: 16px;
    padding: 14px;
}
.alert-black.alert-dismissible {
    padding-right: 35px;
}
.alert-black.alert-dismissible .close,
.alert-dismissible .close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: inherit;
}
.alert-black a {
    color: #bbddfb;
}

/* End of Alert */

.suspendCircle::before,
.redGradientCircle::before {
    background-image: linear-gradient(135deg, #9e2024, #da363b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ownerCircle::before,
.activeCircle::before {
    background-image: linear-gradient(135deg, #0e5a62, #1e8475);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fullAccessCircle::before {
    background-image: linear-gradient(153deg, #261b89, #ba3fcd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.readOnlyCircle::before {
    background-image: linear-gradient(to top, #995d0e, #ba7112);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dateCircle {
    color: #3c7858;
}

.blueCircle::before {
    background-image: linear-gradient(to top, #08588e, #0379c7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.star {
    color: #717171;
}

.starred {
    color: #ffb555;
}

.important,
.errorColor,
.trashHover:hover {
    color: #ca3333;
}

.darkGreen {
    color: #3c7858;
}

.links {
    white-space: normal;
    overflow-x: inherit !important;
    margin-bottom: 30px;
    border-bottom: 0.1875em solid #ddd;

    &:before,
    &:after {
        display: table;
        content: ' ';
    }

    &:after {
        clear: both;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
            float: left;
            margin-bottom: -0.1875em;
            margin-left: 23px;

            &:first-child {
                margin-left: 0;
            }

            a {
                padding: 0;
                font-size: 0.9rem;
                font-weight: bold;
            }

            &.active {
                border-bottom: 0.1875em solid #0071be;

                a,
                a:hover,
                a:focus {
                    color: #222;
                    border: none;
                    border-color: none;
                    background: none;
                }
            }
        }
    }
}

ul.modal-points {
    display: inline-block;
}

ul.modal-points li {
    list-style-type: none;
}

ul.modal-points li > div {
    display: flex;
    align-items: center;
}

.modal-points i {
    font-size: 6px;
    height: 10px;
    margin-right: 10px;
}

.links {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.links::-webkit-scrollbar {
    display: none;
}

.main_container .top_nav {
    display: flex;
    background: #f7f7f7;
}

.create div.flex {
    display: flex;
    flex-wrap: wrap;
}

.create div .col-xs-12.col-md-6:first-child,
.flex .col-xs-12.col-md-6:first-child {
    padding-right: 10px;
}

.create div .col-xs-12.col-md-6:last-child,
.flex .col-xs-12.col-md-6:last-child {
    padding-left: 10px;
}

.create div .col-xs-12.col-md-6:only-child,
.flex .col-xs-12.col-md-6:only-child {
    padding-left: 0;
}

.flex .col-xs-12.col-md-6.paddingLeft0:last-child {
    padding-left: 0;
}

div.createDropdown {
    display: block;
    margin-bottom: 30px;
    width: 100%;
}

.displayNo {
    text-align: right;
}

.viewDropdown {
    width: 100%;
    display: flex;
}

.nav-sm footer {
    margin-left: 0;
}

footer {
    padding: 12px 10px;
    font-size: 12px;
    color: #717171;
    line-height: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 900;
}

@media (min-width: 992px) {
    footer {
        /* left: 110px; */
        left: 135px;
        width: calc(100% - 135px);
    }

    body.test footer {
        left: 0;
    }

    .test footer {
        width: 100%;
    }
}

@media (min-width: 436px) {
    .hidden-min-436 {
        display: none !important;
    }
}

@media (max-width: 436px) {
    .hidden-max-436 {
        display: none !important;
    }

    .padding20.paddingBottom66 {
        padding-bottom: 60px;
    }
}

.switch-toggle-label {
    font-weight: bold;
    line-height: 0.8;
    width: 1.4em;
    margin-left: 10px;
}

/* End of Question Builder Toggle */

/* Steps Navi */

.stepsNaviFixed {
    background: #f7f8fa;
    /*position: fixed;*/
    z-index: 10;
    /*width: calc(100% - 50px);*/
}

@media (min-width: 992px) {
    .stepsNaviFixed {
        /*width: calc(100% - 144.5px);*/
    }
}
.stepsNavi {
    padding-top: 7px;
    padding-bottom: 7px;

    & > a,
    & > div {
        padding: 0 10px;
        color: #d8d8d8;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.viewed {
            color: #0071be;
            opacity: 0.5;
        }

        &.currentStep {
            opacity: 1;
        }
    }

    .fa-long-arrow-alt-right {
        color: #d8d8d8;
        margin: 0 10px;
    }
}

/* End of Steps Navi */

/* Creation */
.creation .header {
    background-color: #fbfbfb;
    padding: 15px 20px;
    font-weight: bold;
}

.creation .body {
    padding: 20px;
}

.creation .body .col-xs-12 .col-xs-6 {
    padding: 0;
}

.creation .body .col-xs-12 .col-xs-6:first-child {
    padding-right: 10px;
}

.creation .body .col-xs-12 .col-xs-6:last-child {
    padding-left: 10px;
}

.genericUsers .col-xs-12.col-md-6:nth-child(3) {
    margin-bottom: 30px;
}
/* End of Creation */

/* dashboard status */

.statusProgression {
    margin-bottom: 20px;
}

.statusProgression:last-child {
    margin-bottom: 0;
}

.statusProgression .bar {
    flex: 1 1 auto;
    border-radius: 15px;
    border: 2px solid #565656;
    background-color: #e5e9f2;
    display: flex;
    margin-top: 5px;
    overflow: hidden;
}

.statusProgression .barProgress {
    height: 30px;
    background-color: #3c7858;
}

.statusProgression .bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
}

/* end of dashboard status */

/* Test */

.testProgression .bar {
    flex: 1 1 auto;
    height: 16px;
    border-radius: 100px;
    background-color: #e5e9f2;
    border: 2px solid #565656;
    border: none;
}

.testProgression .barProgress {
    height: 16px;
    border-radius: 100px;
    background-color: #f5821e;
}

.test-progression.testProgression .bar {
    flex: 1 1 auto;
    height: 30px;
    border-radius: 15px;
    background-color: #e5e9f2;
    border: none;
    display: flex;
}

.test-progression.testProgression .barProgress {
    height: 30px;
    border-radius: 15px;
    background-color: #3c7858;
}

.test-progression.testProgression .bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
}

.test-progression.testProgression .barProgress.bgSolitude {
    background-color: #e5e9f2;
}

/* discussion */

.discussion-bar {
    flex: 1 1 auto;
    height: 30px;
    border-radius: 15px;
    border: none;
    display: flex;
    background-color: #e5e9f2;
    border: 2px solid #565656;
    overflow: hidden;
}

.discussion-bar .barProgress {
    height: 26px;
    color: #fff;
    background-color: #0066b6;
}

.discussion-bar {
    div,
    label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
    }
}
/* end of discussion */

/* question team */

.question-div-bar {
    border: none !important;
    background: none !important;
    padding: 0 !important;
}

.question-bar {
    flex: 1 1 auto;
    height: 30px;
    border-radius: 15px;
    background-color: #0071be;
    border: none;
    display: flex;
    border: 1px solid #d8d8d8;
}

.question-bar .barProgress {
    height: 30px;
    border-radius: 15px;
    color: #fff;
    background-color: #eb6219;
    border: 1px solid #d8d8d8;
    border-right: 1px solid #fff;
}

.question-bar div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
}

/* end of question team */

@media (min-width: 992px) {
    .profileImg {
        width: 140px;
        padding: 0 20px;
    }

    .profileBtns {
        width: 50%;
        margin-left: 140px;
    }

    .nav-sm .container.body .col-md-3.left_col {
        display: flex;
    }

    .nav-sm .container.body .right_col {
        display: block;
        /* margin-left: 110px; */
        margin-left: 135px;
        padding: 0;
    }

    /* question builder */
    .ratingTextDiv {
        width: 33.4%;
    }
    .ratingWeightDiv {
        width: 10%;
    }
}

@media (max-width: 991px) {
    .right_col {
        padding: 0px !important;
    }
    .nav-sm .container.body .col-md-3.left_col {
        min-width: 70px;
        height: calc(100% - 50px);
        position: fixed;
        left: 0;
        top: 50px;
    }

    .nav-sm .container.body .right_col {
        display: block;
        margin-left: 0px;
    }

    .nav-sm .container.body .right_col {
        padding: 0px;
        margin-left: 0px;
        z-index: 2;
    }

    .profileImg {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .profileBtns {
        width: 100%;
        margin-left: 0;
    }
    #suspendModal .modal-body .row div {
        margin-top: 20px;
    }

    #suspendModal .modal-body .row div:first-child {
        margin-top: 0;
    }

    .modal-dialog {
        width: 90%;
    }

    .resetPasswordDropDown {
        margin-top: 20px;
    }

    .col-12 {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        margin: 30px auto;
        min-width: 600px;
    }

    footer {
        margin-left: 0;
    }
}

@media (max-width: 450px) {
    .modal-dialog {
        transform: none;
        margin: 0;
        width: 100%;
        position: inherit;
        left: 0;
        height: 100%;

        .modal-content {
            height: 100%;
            overflow: auto;
        }
    }
}

/* panel */

.gradient.panel {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
}

.gradient.panel .panel-heading {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    border-bottom: 1px solid #d8d8d8;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    font-weight: bold;

    &.padding10 {
        padding: 10px;
    }
}

.gradient.panel .panelFooter {
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    align-items: center;
    padding: 20px;
    border-top: 1px solid #d8d8d8;

    &.padding10 {
        padding: 10px;
    }
}

.panel .mini-panel .panel-heading {
    background-image: none;
    background: #f6f8fa;
}

.gradient.panel:last-child,
.x_panel:last-child {
    margin-bottom: 0;
}

/* End of Panel */

/* Additional CSS */
.primaryColor {
    color: #546ea9;
}

.primaryBackground {
    background-color: #546ea9;
    color: #fff;
}

.disabledColor {
    color: #717171;
}

.successColor {
    color: #3c7858;
}

.successBackground {
    background-color: #3c7858;
    color: #fff;
}

.newCorrectColor {
    color: #497970;
}

.requirements-notComplete {
    color: #8d2424;
}

.requirements-complete {
    color: #2a543d;
}

.warningColor {
    color: #985e12;
}

.warningBackground {
    background-color: #985e12;
    color: #fff;
}

.dangerColor {
    color: #ca3333;
}

.dangerBackground {
    background-color: #ca3333;
    color: #fff;
}

.censorBackground {
    background-color: #fdecec;
    color: #ba2929;
}

.widthAuto {
    width: auto;
}

.minWidthAuto {
    min-width: auto;
}

.minWidth0 {
    min-width: 0;
}

.maxWidth100 {
    max-width: 100%;
}

.maxWidth50px {
    max-width: 50px;
}

.minWidth62px {
    min-width: 62px;
}

.width62px {
    width: 62px;
}

.maxWidth62px {
    max-width: 62px;
}

.minWidth100px {
    min-width: 100px;
}

.maxWidth100px {
    max-width: 100px;
}

.minWidth110px {
    min-width: 110px;
}

.maxWidth110px {
    max-width: 110px;
}

.minWidth120px {
    min-width: 120px;
}

.maxWidth120px {
    max-width: 120px;
}

.minWidth130px {
    min-width: 130px;
}

.maxWidth130px {
    max-width: 130px;
}

.maxWidth132px {
    max-width: 132px;
}

.maxWidth142px {
    max-width: 142px;
}

.minWidth150px {
    min-width: 150px;
}

.maxWidth150px {
    max-width: 150px;
}

.maxWidth200px {
    max-width: 200px;
}

.maxWidth560px {
    max-width: 560px;
}
.maxWidth600px {
    max-width: 600px;
}

.maxWidth800px {
    max-width: 800px;
}

.maxWidth2000px {
    max-width: 2000px;
}

.maxHeight100px {
    max-height: 100px;
    overflow: hidden;
}

.maxHeight500pxAuto {
    max-height: 500px;
    overflow: auto;
}

.width130px {
    width: 130px;
}

.width132px {
    width: 132px;
}

.width190px {
    width: 190px;
}

.minWidth500px {
    min-width: 500px;
}

.width500px {
    width: 500px;
}

.maxWidth500px {
    max-width: 500px;
}

.minWidth364px {
    min-width: 364px;
}

.maxWidth82 {
    max-width: 82px;
}

.maxWidth5em {
    max-width: 5em;
}

.maxWidth1000px {
    max-width: 1000px;
}

.maxWidth600px {
    max-width: 600px;
}

.marginLeft0 {
    margin-left: 0;
}

.marginRight0 {
    margin-right: 0px;
}

.marginBottom0,
.mb-0 {
    margin-bottom: 0;
}

.marginTop0 {
    margin-top: 0;
}

.marginTop2 {
    margin-top: 2px;
}

.marginLeft2 {
    margin-left: 2px;
}

.marginLeft5 {
    margin-left: 5px;
}

.marginLeft8 {
    margin-left: 8px;
}

.marginTop3 {
    margin-top: 3px;
}

.marginTop5 {
    margin-top: 5px;
}

.marginBottom5 {
    margin-bottom: 5px;
}

.marginRight5 {
    margin-right: 5px;
}

.marginRight8 {
    margin-right: 8px;
}

.paddingBottom0 {
    padding-bottom: 0;
}

.marginTop8 {
    margin-top: 8px;
}

.marginBottom8 {
    margin-bottom: 8px;
}

.padding8 {
    padding: 8px;
}

.margin0 {
    margin: 0px;
}

.margin10 {
    margin: 10px;
}

.padding10 {
    padding: 10px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginBottom10,
.mb-10 {
    margin-bottom: 10px;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginRight10 {
    margin-right: 10px;
}

.gap10 {
    gap: 10px;
}

.marginTop15 {
    margin-top: 15px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.marginRight15 {
    margin-right: 15px;
}

.padding15 {
    padding: 15px;
}

.paddingBottom16 {
    padding-bottom: 16px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginRight20 {
    margin-right: 20px;
}

.gap20 {
    gap: 20px;
}

.marginTop25 {
    margin-top: 25px;
}

.marginBottom25 {
    margin-bottom: 25px;
}

.marginTop30 {
    margin-top: 30px;
}

.marginLeft30 {
    margin-left: 30px;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginTop40 {
    margin-top: 40px;
}

.marginBottom40 {
    margin-bottom: 40px;
}

.marginLeft50 {
    margin-left: 50px;
}

.marginTop55 {
    margin-top: 55px;
}

.marginBottom60 {
    margin-bottom: 60px;
}

.padding0 {
    padding: 0 !important;
}

.paddingTop0 {
    padding-top: 0;
}

.paddingLeft0 {
    padding-left: 0px;
}

.paddingRight0 {
    padding-right: 0px;
}

.paddingTop2 {
    padding-top: 2px;
}

.paddingTop3 {
    padding-top: 3px;
}

.paddingBottom3 {
    padding-bottom: 3px;
}

.padding5 {
    padding: 5px;
}

.paddingTop5 {
    padding-top: 5px;
}

.paddingBottom5 {
    padding-bottom: 5px;
}

.paddingLeft5 {
    padding-left: 5px;
}

.paddingRight5 {
    padding-right: 5px;
}

.padding6 {
    padding: 6px;
}

.paddingTop6 {
    padding-top: 6px;
}

.padding8 {
    padding: 8px;
}

.paddingTop8 {
    padding-top: 8px;
}

.paddingTop10 {
    padding-top: 10px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.paddingRight10 {
    padding-right: 10px;
}

.paddingTop15 {
    padding-top: 15px;
}

.paddingLeft15 {
    padding-left: 15px;
}

.paddingRight15 {
    padding-right: 15px;
}

.padding16 {
    padding: 16px;
}

.paddingTop16 {
    padding-top: 16px;
}

.paddingBottom16 {
    padding-bottom: 16px;
}

.paddingLeft16 {
    padding-left: 16px;
}

.paddingRight16 {
    padding-right: 16px;
}

.paddingTop18 {
    padding-top: 18px;
}

.padding20 {
    padding: 20px;
}

.paddingTop20 {
    padding-top: 20px;
}

.padding24 {
    padding: 24px;
}

.paddingTop24 {
    padding-top: 24px;
}

.paddingBottom24 {
    padding-bottom: 24px;
}

.paddingLeft24 {
    padding-left: 24px;
}

.paddingRight24 {
    padding-right: 24px;
}

.paddingTop25 {
    padding-top: 25px;
}

.paddingLeft20 {
    padding-left: 20px;
}

.paddingRight20 {
    padding-right: 20px;
}

.paddingBottom30 {
    padding-bottom: 30px;
}

.paddingLeft32 {
    padding-left: 32px;
}

.paddingLeft35px {
    padding-left: 35px;
}

.paddingLeft75 {
    padding-left: 75px;
}

.padding10px20px {
    padding: 10px 20px;
}

.padding70px25px {
    padding: 70px 25px;
}

.paddingBottom60 {
    padding-bottom: 60px;
}

.paddingBottom66 {
    padding-bottom: 66px;
}

.activityName {
    font-size: 0.889rem;
    color: #222;
}

.size14 {
    font-size: 0.778rem;
    line-height: 1.2;
}

.greyText {
    color: #717171;
}

.whiteText {
    color: #fff;
}

.fontBold {
    font-weight: bold;
}

.fontNormal {
    font-weight: normal;
}

.textSuccess {
    color: #3c7858;
}

.textAlignLeft {
    text-align: left;
}

.textAlignCenter,
.textCenteralize {
    text-align: center;
}

.textAlignRight {
    text-align: right;
}

.height15px {
    height: 15px;
}

.height20px {
    height: 20px;
}

.height45px {
    height: 45px;
}

.height100 {
    height: 100%;
}

.height100px {
    height: 100px;
}

.heightAuto {
    height: auto;
}

.heightMax100px {
    height: auto;
    max-height: 100px;
    overflow: auto;
}

.heightMax200px {
    height: auto;
    max-height: 200px;
    overflow: auto;
}

.maxHeight420px {
    max-height: 420px;
}

.width10px {
    width: 10px;
}

.width18px {
    width: 18px;
}

.width20 {
    width: 20%;
}

.width24px {
    width: 24px;
}

.width30px {
    width: 30px;
}

.width36px {
    width: 36px;
}

.width38px {
    width: 38px;
}

.widthMax36px {
    max-width: 36px;
}

.width40 {
    width: 40%;
}

.width45px {
    width: 45px;
}

.width50px {
    width: 50px;
}

.width52px {
    width: 52px;
}

.width55px {
    width: 55px;
}

.width68px {
    width: 68px;
}

.minWidth68px {
    min-width: 68px;
}

.maxWidth68px {
    max-width: 68px;
}

.width65px {
    width: 65px;
}

.width65pxImp {
    width: 65px !important;
}

.maxWidth70px {
    max-width: 70px;
}

.width73px {
    width: 73px;
}

.minWidth73px {
    min-width: 73px;
}

.maxWidth73px {
    max-width: 73px;
}

.minWidth75px {
    min-width: 75px;
}

.width75px {
    width: 75px;
}

.maxWidth75px {
    max-width: 75px;
}

.minWidth76px {
    min-width: 76px;
}

.width76px {
    width: 76px;
}

.maxWidth76px {
    max-width: 76px;
}

.width78px {
    width: 78px;
}

.minWidth78px {
    min-width: 78px;
}

.maxWidth78px {
    max-width: 78px;
}

.minWidth80px {
    min-width: 80px;
}

.width80px {
    width: 80px;
}

.maxWidth80px {
    max-width: 80px;
}

.width82 {
    width: 81%;
}

.minWidth85px {
    min-width: 85px;
}

.width85px {
    width: 85px;
}

.maxWidth85px {
    max-width: 85px;
}

.minWidth88px {
    min-width: 88px;
}

.width88px {
    width: 88px;
}

.maxWidth88px {
    max-width: 88px;
}

.minWidth90px {
    min-width: 90px;
}

.width90px {
    width: 90px;
}

.maxWidth90px {
    max-width: 90px;
}

.minWidth95px {
    min-width: 95px;
}

.width95px {
    width: 95px;
}

.maxWidth95px {
    max-width: 95px;
}

.width98px {
    width: 98px;
}

.width100px {
    width: 100px;
}

.width100 {
    width: 100%;
}

.width103px {
    width: 103px;
}

.width105px {
    width: 105px;
}

.minWidth105px {
    min-width: 105px;
}

.maxWidth105px {
    max-width: 105px;
}

.width108px {
    width: 108px;
}

.minWidth108px {
    min-width: 108px;
}

.maxWidth108px {
    max-width: 108px;
}

.minWidth110px {
    min-width: 110px;
}

.width110px {
    width: 110px;
}

.maxWidth110px {
    max-width: 110px;
}

.width113px {
    width: 113px;
    max-width: 115px;
}

.minWidth115px {
    min-width: 115px;
}

.width115px {
    width: 115px;
}

.maxWidth115px {
    max-width: 115px;
}

.width118px {
    width: 118px;
}

.minWidth118px {
    min-width: 118px;
}

.maxWidth118px {
    max-width: 118px;
}

.width120px {
    width: 120px;
}

.minWidth120px {
    min-width: 120px;
}

.maxWidth120px {
    max-width: 120px;
}

.minWidth123px {
    min-width: 123px;
}

.width123px {
    width: 123px;
}

.maxWidth123px {
    max-width: 123px;
}

.minWidth125px {
    min-width: 125px;
}

.width125px {
    width: 125px;
}

.maxWidth125px {
    max-width: 125px;
}

.width130px {
    width: 130px;
}

.width132px {
    width: 132px;
}

.minWidth132px {
    min-width: 132px;
}

.minWidth140px {
    min-width: 140px;
}

.width140px {
    width: 140px;
}

.maxWidth140px {
    max-width: 140px;
}

.width148px {
    width: 148px;
}

.width150px {
    width: 150px;
}

.minWidth152px {
    min-width: 152px;
}

.width152px {
    width: 152px;
}

.maxWidth152px {
    max-width: 152px;
}

.minWidth154px {
    min-width: 154px;
}

.width154px {
    width: 154px;
}

.maxWidth154px {
    max-width: 154px;
}

.minWidth160px {
    min-width: 160px;
}

.width160px {
    width: 160px;
}

.maxWidth160px {
    max-width: 160px;
}

.width166px {
    width: 166px;
}

.minWidth175px {
    min-width: 175px;
}

.width175px {
    width: 175px;
}

.maxWidth175px {
    max-width: 175px;
}

.width192px {
    width: 192px;
}

.minWidth194px {
    min-width: 194px;
}

.minWidth195px {
    min-width: 195px;
}

.width195px {
    width: 195px;
}

.maxWidth195px {
    max-width: 195px;
}

.width230px {
    width: 230px;
}

.minWidth250px {
    min-width: 250px;
}

.width250px {
    width: 250px;
}

.maxWidth250px {
    max-width: 250px;
}

.maxHeight255px {
    max-height: 255px;
}

.width266px {
    width: 266px;
}

.minWidth266px {
    min-width: 266px;
}

.maxWidth266px {
    max-width: 266px;
}

.width160px {
    width: 160px;
}

.minWidth160px {
    min-width: 160px;
}

.maxWidth160px {
    max-width: 160px;
}

.width180px {
    width: 180px;
}

.minWidth180px {
    min-width: 180px;
}

.maxWidth180px {
    max-width: 180px;
}

.minWidth200px {
    min-width: 200px;
}

.width200px {
    width: 200px;
}

.maxWidth200px {
    max-width: 200px;
}

.width205px {
    width: 205px;
}

.minWidth205px {
    min-width: 205px;
}

.maxWidth205px {
    max-width: 205px;
}

.minWidth210px {
    min-width: 210px;
}

.width210px {
    width: 210px;
}

.maxWidth210px {
    max-width: 210px;
}

.minWidth216px {
    min-width: 216px;
}

.width216px {
    width: 216px;
}

.maxWidth216px {
    max-width: 216px;
}

.minWidth300px {
    min-width: 300px;
}

.width300px {
    width: 300px;
}

.maxWidth300px {
    max-width: 300px;
}

.minWidth330px {
    min-width: 330px;
}

.width330px {
    width: 330px;
}

.maxWidth330px {
    max-width: 330px;
}

.minWidth350px {
    min-width: 350px;
}

.width350px {
    width: 350px;
}

.maxWidth350px {
    max-width: 350px;
}

.minWidth360px {
    min-width: 360px;
}

.width360px {
    width: 360px;
}

.maxWidth360px {
    max-width: 360px;
}

.width370px {
    width: 370px;
}

.minWidth400px {
    min-width: 400px;
}

.width400px {
    width: 400px;
}

.maxWidth400px {
    max-width: 400px;
}

.width600px {
    width: 600px;
}

.fs-10px {
    font-size: 10px;
}

.fontSize12,
.fs-12px {
    font-size: 0.667rem;
}

.fontSize14,
.fs-14px {
    font-size: 0.778rem;
}

.fontSize16,
.fs-16px {
    font-size: 0.889rem;
}

.fontSize18,
.fs-18px {
    font-size: 1rem;
}

.fontSize20,
.font20,
.fs-20px {
    font-size: 1.111rem;
}

.fontSize22,
.fs-22px {
    font-size: 1.25rem;
}

.fontSize24 {
    font-size: 24px;
}

.fs-26px {
    font-size: 1.44rem;
}

.fs-28px {
    font-size: 1.556rem;
}

.font30 {
    font-size: 1.667rem;
}

.fontSize26 {
    font-size: 1.444rem;
}

.fontSize32 {
    font-size: 1.778rem;
}

.fs-70px {
    font-size: 3.889rem;
}

.displayInlineTable {
    display: inline-table;
}

.displayInline {
    display: inline;
}

.fontBlack,
.fa-sort-amount-asc,
.fa-sort-amount-desc,
.fa-sort {
    color: #222;
}

.fontRed {
    color: #ca3333;
}

.fontGrey {
    color: #717171;
}

.lineHeight1 {
    line-height: 1;
}

.lineHeight1_2 {
    line-height: 1.2;
}

textarea {
    width: 100%;
    border: 1px solid #717171;
    background: #fff;
    border-radius: 8px;
    color: #222;
    resize: none;

    &:focus {
        border: 1px solid #0071be;
    }

    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }

    &:disabled,
    &.isDisabled {
        background: #efefef;
        cursor: not-allowed;
        color: #222;
        user-select: none;
        pointer-events: none;
        opacity: 1;
        -webkit-text-fill-color: #222;

        &.hasToolTip {
            pointer-events: inherit;
        }
    }
}

.tox-tinymce {
    border: 1px solid #717171 !important;

    &--disabled {
        .tox-editor-header,
        .tox-toolbar-overlord,
        .tox-toolbar,
        .tox-statusbar,
        .tox-edit-area__iframe {
            background-color: #efefef !important;
            color: #354052 !important;
        }

        .tox-tbtn--disabled svg {
            fill: rgb(74 90 109 / 80%) !important;
        }
    }
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder {
    color: #717171;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
    font-style: italic;
}

textarea,
.form-control {
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
        font-style: italic;
    }
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.borderLeft0 {
    border-left: 0;
}

.wordBreakAll {
    word-break: break-all;
}

.whiteSpacePreWrap {
    white-space: pre-wrap;
}

.whiteSpacePreLine {
    white-space: pre-line;
}

.wordBreakWord {
    overflow-wrap: break-word;
    word-break: break-word;
}

.lineHeight08 {
    line-height: 0.8;
}

/* End of additional css */

.highlight {
    background-color: rgba(167, 255, 239, 0.5);
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
}

.blue {
    color: #0071be;
}

.displayNone {
    display: none;
}

.collapseText {
    font-size: 14px;
    text-transform: capitalize;
    line-height: 0.5;
}

.collapseText i {
    font-size: 10px;
    vertical-align: middle;
}

.input-group {
    margin: 0;
}

.displayInlineBlock {
    display: inline-block;
}

.fontItalic {
    font-style: italic;
}

.pointer {
    cursor: pointer;
}

.no-error .help-block {
    display: none;
}
.no-error.has-error .help-block {
    display: block;
}

/* background color */

.colorRedBg,
.barProgress.colorRedBg {
    background-color: #ca3333;
}

.colorGreenBg,
.barProgress.colorGreenBg {
    background-color: #3c7858;
}

.colorYellowBg,
.barProgress.colorYellowBg {
    background-color: #d0a104;
}

.colorBlueBg,
.barProgress.colorBlueBg {
    background-color: #0071be;
}

/* end of background color */

.panel-div {
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.border1pxGrey {
    border: 1px solid #d8d8d8;
}

.borderTop1pxGrey {
    border-top: 1px solid #d8d8d8;
}

.borderLeft1pxGrey {
    border-left: 1px solid #d8d8d8;
}

.borderBottom1pxGrey {
    border-bottom: 1px solid #d8d8d8;
}

.borderBottom2pxGrey {
    border-bottom: 2px solid #d8d8d8;
}

.borderTop1pxWhite {
    border-top: 1px solid #ffffff;
}

.boxShadow {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

.borderRadius0,
.br-0 {
    border-radius: 0;
}

.borderRadius3px,
.br-3 {
    border-radius: 3px;
}

.backgroundGradient {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
}

.clear {
    clear: both;
}

.padding6px12px {
    padding: 6px 12px;
}

.daterangepicker.ltr .calendar.left {
    margin: 0 !important;
}

.daterangepicker {
    .glyphicon-chevron-right:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f054';
        font-weight: 900;
    }

    .glyphicon-chevron-left:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f053';
        font-weight: 900;
    }

    .table-condensed {
        border: none;
        box-shadow: none;
    }

    .calendar-table {
        padding: 0 !important;
    }
}

.toggleArrow {
    &:before {
        content: 'Less ';
        text-transform: capitalize;
        font-size: 12px;
    }
    &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f0d8';
        font-size: 12px;
        font-weight: 900;
    }
}

.collapsed .toggleArrow {
    &:before {
        content: 'More ';
        text-transform: capitalize;
        font-size: 12px;
    }
    &:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f0d7';
        font-size: 12px;
        font-weight: 900;
    }
}

.vcenter {
    vertical-align: middle !important;
}

.cursor-norm {
    cursor: inherit;
}

.previewModelTeam ul {
    list-style-type: none;
    padding: 0;
}

.minWidth82px {
    min-width: 82px;
}

.with-top-gaps {
    margin-top: 10px;
}

.with-top-gaps:first-child {
    margin-top: 0;
}

.disabled-background {
    background: #d8d8d8 !important;
}

.right-container {
    box-sizing: border-box;
    padding: 20px 20px 60px 20px;
}

.payment-alert {
    padding-left: 25px;
    padding-right: 25px;
}

.dashboard.right-container {
    box-sizing: border-box;
    padding-top: 20px;
}

.right-container.timer {
    padding: 130px 25px 61px 25px;
}

.test .timer ~ .right-container,
.test .timer ~ .right-container.group,
.test .right-container,
.test .right-container.group {
    padding: 30px 25px 73px 25px !important;
}

@media (max-width: 991.98px) {
    .right-container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .payment-alert {
        padding-left: 20px;
        padding-right: 20px;
    }

    .dashboard.right-container {
        box-sizing: border-box;
        padding-top: 10px;
    }
}

.p-0-i {
    padding: 0 !important;
}

.presentation-mode-cr {
    .nav-tabsContainer {
        margin-top: 0;
    }

    .links {
        border-bottom: 4px solid #ddd;
    }
}

/* e-gallery */
.eGalleryQuestionGroup,
.mini-panel {
    .panel-heading {
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
        box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
    }

    .panel-body {
        &.height100 {
            height: 100%;
        }
    }

    .panel-heading,
    .panel-body {
        background: #eff2f6;
    }

    .panel-footer {
        padding: 20px;
        border-top: 1px solid #d8d8d8;
        box-shadow: 0 -2px 4px 0 rgba(168, 168, 168, 0.5);
        background: #fff;
    }

    .night-mode {
        .panel-heading {
            background: #273142;
            color: #fff;
        }

        .panel-body {
            background: #273142;
            color: #fff;
        }

        .darkGreen {
            .timeText {
                color: #45b86f;
            }
        }
    }
}

@media (min-width: 992px) {
    body.test .eGalleryQuestionGroup .panel-body,
    .eGalleryQuestionGroup .panel-body,
    .mini-panel .panel-body {
        white-space: pre-line;
        padding: 15px 20px;
    }

    .eGalleryQuestionGroup .panel,
    .mini-panel .panel {
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
    }
}

@media (max-width: 991px) {
    .eGalleryQuestionGroup {
        .panel {
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            background-color: #ffffff;
            border: 1px solid #ededed;
        }

        .panel-body {
            overflow: hidden;
            overflow-y: auto;
            white-space: pre-line;
        }
    }
}

.eGalleryQuestionGroup {
    .panel-body {
        & > div {
            background: #fff;
            border: 1px solid #d8d8d8;
            border-radius: 5px;
        }
    }
}

.mini-panel {
    .panel-body {
        background: #fff;
    }

    .panel-body {
        & > div {
            padding: 0;
            border: none;
        }
    }
}

/* end of e-gallery */

.cr-display-columns {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.displayInherit {
    display: inherit;
}

.course-thumbnail,
.institution-thumbnail {
    width: 400px;
    height: 200px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .preview-image,
    .actual-image {
        margin: 0;
    }
}

.pageTitle {
    margin-bottom: 20px;

    h1 {
        margin-top: 0;
        font-size: 1.333rem;
        font-weight: bold;
        margin-bottom: 4px;
    }

    p {
        color: #717171;
        font-size: 1rem;
        line-height: 1.5;
    }

    a {
        color: #1071bc;

        .fa-info-circle {
            font-size: 12px;
        }
    }
}

.panelTitle {
    margin-bottom: 20px;

    p {
        color: #717171;
        font-size: 1rem;
        line-height: 1.5;
    }
}

.opacity-7 {
    opacity: 0.7;
}

.lh-0 {
    line-height: 0;
}

.lh-0-5 {
    line-height: 0.5;
}

.lh-0-8 {
    line-height: 0.8;
}

.lh-1 {
    line-height: 1;
}

.lh-1-2 {
    line-height: 1.2;
}

.lh-1-3 {
    line-height: 1.3;
}

.lh-1-5 {
    line-height: 1.5;
}

.border-collapse {
    border-collapse: collapse;
}

.btn-group {
    display: inline-flex;
}

.minWidth5em {
    min-width: 5em;
}

.test-customize-header {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    border: 1px solid #d8d8d8;
    border-bottom: 0;
}

.bt-0 {
    border-top: 0;
}

.td-underline {
    text-decoration: underline;
}

.panel-content-subHeader {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 15px;
    line-height: 1;
    font-size: 20px;
}

.table-keyword-highlight {
    background-color: #ffff00;
}

.whiteSpaceNowrap {
    white-space: nowrap;
}

a.link.noBg {
    &:hover {
        background: none;
    }
}

a.disabled,
a.link.disabled {
    color: #c6c6c6;
}

.itemAnalysisTable {
    table {
        border: solid 1px #d8d8d8;

        th {
            background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        }

        tr:not(:last-child) {
            th,
            td {
                border-bottom: solid 1px #d8d8d8;
            }
        }
    }

    .row {
        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .card {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .card-header {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                padding: 8px;
                font-weight: bold;

                border: solid 1px #d8d8d8;
                background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
            }

            .card-body {
                padding: 8px;
                border: solid 1px #d8d8d8;
                border-top: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    @media (max-width: 345px) {
        display: block;

        .col-xs-4.col-sm-4.col-md-4 {
            width: 100%;
            margin-top: 20px;
        }
    }

    @media (min-width: 346px) {
        .col-xs-4.col-sm-4.col-md-4 {
            padding-left: 10px;
        }
    }
}

.visible-hidden {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.visible-hidden:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
}

.borderNone {
    border: none;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

@media (min-width: 992px) {
    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }
}

.zIndex1001 {
    z-index: 1001;
}

html,
body {
    font-size: 1rem;
}

h1 {
    font-size: 1.83rem;
    font-weight: bold;
}

h2,
.fs-29px {
    font-size: 1.61rem;
    font-weight: bold;
}

.x_title {
    h1,
    h2 {
        font-size: 1rem;
        margin: 0;
    }

    h3 {
        font-size: 0.88rem;
        margin-bottom: 0;

        &.fs-18px {
            font-size: 1rem;
        }
    }
}

@media (max-width: 768px) {
    .x_title {
        h2 {
            margin-bottom: 10px;

            &.marginBottom0 {
                margin-bottom: 0;
            }
        }
    }
}

h3,
.fs-26px {
    font-size: 1.44rem;
    font-weight: bold;
}

h4,
.fs-24px {
    font-size: 1.33rem;
}

h5 {
    font-size: 1.11rem;
}

p,
.fontSize18,
.fs-18px {
    font-size: 1rem;
}

label,
.fontSize16 {
    font-size: 0.88rem;
}

.fw-normal {
    font-weight: normal;
}

.fw-bold {
    font-weight: bold;
}

.savedTime {
    padding-left: calc(1.5rem + 8px + 10px);
    font-size: 0.88rem;
    font-style: italic;
    color: #717171;
}

.flexInheritBtn {
    .btn {
        flex: inherit;
        margin-left: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.autoFlex {
    display: flex;
    flex-wrap: wrap;

    .btn,
    .dropdown {
        margin-left: 0;
        flex: inherit;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 10px;

        #viewDropdownMenuButton,
        .margin0 {
            &.btn {
                margin: 0;
            }
        }
    }

    &.justify-content-right {
        justify-content: right;

        .btn,
        .dropdown {
            margin-right: 0;
            margin-left: 10px;

            #viewDropdownMenuButton {
                &.btn {
                    margin-left: 0;
                }
            }
        }
    }

    .dropdown-menu {
        .btn {
            margin: 0;
        }
    }
}

button,
.buttons,
.btn,
router-link,
.statusTag {
    padding: 6px 12px;
}

.transparent {
    &.table {
        td {
            .gradient.btn-default {
                padding: 6px;
            }
        }
    }
}

.loadingAnimation {
    display: flex;
    justify-content: center;

    &:after {
        content: ' .';
        animation: dots 1s steps(5, end) infinite;
    }
}

@keyframes dots {
    0% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
    }
    100% {
        text-shadow: 0.25em 0 0 #000, 0.5em 0 0 #000;
    }
}

.merge2ElementsFieldButton {
    display: flex;

    button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
    }
}

.form-search {
    .form-control {
        padding-right: 30px;
    }
}

.searchDropdown {
    position: absolute;
    top: auto;
    bottom: 60%;
    left: 0;
    z-index: 1000;
    min-width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    margin-top: 10px;
    margin-bottom: 10px;

    &--down {
        top: 100%;
        bottom: auto;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .btn {
        width: 100%;
        border-radius: 0;
        padding: 16px;
        border-bottom: 1px solid #eee;
        font-weight: normal;
        display: block;
        text-align: left;

        &:hover {
            background-color: #e4e4e4;
        }
    }
}

.textWrap {
    white-space: inherit;
}

.textWrapAuto {
    text-wrap: auto;
}

.flexInherit {
    flex: inherit;
}

.attendanceTag {
    &-locked {
        background: #fab8b8;
        border: 1px solid #fab8b8;
        color: #222;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-primary {
        background: #ebf3fe;
        border: 1px solid #ebf3fe;
        color: #222;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-green {
        background: #e5fff5;
        border: 1px solid #e5fff5;
        color: #222;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-gray {
        background: #efefef;
        border: 1px solid #efefef;
        color: #8b8b8f;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-purple {
        background: #f3e8fc;
        border: 1px solid #f3e8fc;
        color: #3c3c43;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }

    &-blue {
        background: #dbfbff;
        border: 1px solid #dbfbff;
        color: #3c3c43;

        line-height: 0.8;
        border-radius: 44px;
        padding: 6px 8px;

        p {
            font-size: 0.88rem;
        }

        i {
            line-height: 0.8;
            font-size: 0.8rem;
        }
    }
}

.timerTag {
    &-earlyStart {
        border: 1px solid #0071be;
        color: #222;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }

    &-success {
        border: 1px solid #3c7858;
        color: #3c7858;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }

    &-ended {
        border: 1px solid #717171;
        color: #717171;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }

    &-primary {
        border: 1px solid #0071be;
        color: #0071be;
        line-height: 0.8;
        padding: 4px 8px;

        p {
            font-size: 0.88rem;
        }
    }
}

.color-emoji-light-success {
    color: #3bb44b;
}

.color-success {
    color: #3c7858;
}

.color-emoji-success {
    color: #26af66;
}

.color-danger {
    color: #ca3333;
}

.color-emoji-danger {
    color: #d24b4b;
}

.color-dark-orange {
    color: #8a5016;
}

.color-emoji-warning {
    color: #e38930;
}

.color-emoji-yellow {
    color: #f0b32d;
}

.emojiAddStroke {
    text-shadow: -1.5px -2px 0 #000, 1.5px -2px 0 #000, -1.5px 2px 0 #000000, 1.5px 2px 0 #000000;
}

.border0 {
    border: 0;
}

.toggle-token {
    position: relative;
    width: fit-content;
    left: 7%;

    input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        &:focus-within {
            outline: 3px solid #1941a6;
            outline-offset: 3px;
        }

        & + label {
            padding: 6px 12px;
            padding-top: 8px;
            border-radius: 8px;
            background: #3c7858;
            color: #fff;
            border: 2px solid transparent;
        }

        &:checked + label {
            background: #cccccc;
            color: #222;
        }

        &:hover,
        &:focus-visible {
            & + label {
                background: #fff;
                border-color: #00582f;
                color: #3c3c43;
                cursor: pointer;
            }

            &:checked + label {
                background: #fff;
                border-color: #858585;
                color: #3c3c43;
                cursor: pointer;
            }
        }
    }
}

.card-body-cons,
.word-wrap-break-word {
    word-wrap: break-word;
}

.tox-tinymce {
    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.bs-0 {
    box-shadow: none;
}

.accommodationsStudentList {
    border-color: #efefef;
    border-top: 0;
    border-collapse: collapse;

    thead {
        background: #e0e0e0;
    }

    th {
        border-right-color: #efefef;
        padding: 6px 12px;
    }

    tr {
        border-top: 1px solid #e0e0e0;

        td {
            border-right-color: #efefef;
            padding: 6px 12px;
        }
    }

    &.tableBodyFixedHeight {
        thead,
        tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }

        tbody {
            display: block;
            max-height: 400px;
            overflow-y: auto;
        }

        .selectAllFixedWidth {
            width: 138px;
            max-width: 138px;
        }
    }
}

.color-purple {
    color: #761fbb;
}

.panel-default {
    .panel-collapse {
        .panel-body.borderNone {
            border: none;
        }
    }
}

.overflowYHidden {
    overflow-y: hidden;
}

mark {
    &.offWhiteHighlight {
        background: #e2e2e2;
    }
}
div {
    &.scrollable-table-wrapper {
        overflow: auto;
        max-height: 500px;
        min-height: 14px;
    }
}

.color-dark-blue {
    color: #0071be;
}

.fa-stack {
    &.small {
        font-size: 0.6rem;

        i {
            vertical-align: middle;
        }
    }
}

.background-EFEFEF {
    background: #efefef;
}

.whiteSpaceInherit {
    white-space: inherit;
}

.whiteSpaceBreakSpacesText {
    white-space: break-spaces;

    .questionDiv {
        white-space: break-spaces;
    }
}

.new-primary-color {
    &:hover {
        color: #0071be;
    }
}

.question {
    &.presentationMode {
        img {
            max-width: 100%;
        }
    }
}

.publishAccommodationsBlue {
    color: #1891eb;
}

.right-corner-triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 35px;
    text-align: right;
    padding: 5px 5px;
    color: #222;
    background: yellow;
    clip-path: polygon(100% 100%, 100% 0%, 0% 0%);
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.copiedIndicationBar {
    background: yellow;
    padding: 0px 20px;
    text-align: center;
    text-transform: uppercase;

    label {
        font-weight: normal;
    }
}

.vue-simple-suggest.designed {
    .input-wrapper {
        input {
            border: 1px solid #717171 !important;

            &:focus {
                border: 1px solid #0071be !important;
                outline: 0;
            }

            &:focus-visible {
                border: 1px solid #0071be !important;
                outline: 4px solid #1941a6;
                box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
            }
        }
    }
}

.overflowAuto {
    overflow: auto;
}

.questionBankTags {
    padding-left: 7px;
    padding-right: 7px;

    li {
        display: inline;
    }

    .tag {
        background-color: #495060;
        border-radius: 4px 3px 3px 4px;
        position: relative;
        color: #fff;
        display: inline-block;
        font-size: 1rem;
        line-height: 22px;
        padding: 0 8px 0 10px;
        margin: 4px 4px 4px 8px;

        &:before {
            content: '';
            position: absolute;
            left: -5px;
            top: 1px;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-right: 6px solid #495060;
            border-bottom: 10px solid transparent;
        }

        &:after {
            border: none;
        }
    }
}

.div-focusable {
    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.custom-arrow-icon {
    width: 10px;
    height: 22px;
    transform: translateX(3px) translateY(0);
    transition: transform 0.1s;

    &-top,
    &-bottom {
        background-color: #222;
        height: 2px;
        left: -5px;
        position: absolute;
        top: 50%;
        width: 100%;

        &:after {
            background-color: #0071be;
            content: '';
            height: 100%;
            position: absolute;
            top: 0;
            transition: all 0.15s;
        }
    }

    &-top {
        transform: rotate(45deg);
        transform-origin: bottom right;

        &:after {
            left: 100%;
            right: 0;
            transition-delay: 0s;
        }
    }

    &-bottom {
        transform: rotate(-45deg);
        transform-origin: top right;

        &:after {
            left: 0;
            right: 100%;
            transition-delay: 0.15s;
        }
    }

    &:hover & {
        &-top {
            background-color: transparent;

            &:after {
                left: 0;
                transition-delay: 0.15s;
            }
        }

        &-bottom {
            background-color: transparent;

            &:after {
                right: 0;
                transition-delay: 0s;
            }
        }
    }

    &:active {
        transform: translateX(3px) translateY(0) scale(0.8);
    }
}

.cardCons-question-bank {
    margin: 0 0 20px 20px;
    width: calc(100% - 20px);

    font-size: inherit;
    text-align: inherit;
    font-weight: inherit;
    padding: inherit;
    height: 100%;

    .card {
        border-radius: 3px;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        box-shadow: 4px 4px 4px 0 rgba(202, 202, 202, 0.5);
        position: relative;
    }

    .card-header {
        background-color: #495060;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 15px;
        line-height: 1;

        div {
            white-space: nowrap;
            overflow: hidden;
        }

        position: relative;
    }

    .card-body {
        border: 1px solid transparent;

        display: flex;
        flex-wrap: wrap;
        word-wrap: break-word;
        padding: 10px 15px;
        height: 120px;

        .question-area {
            max-height: 100%;
            overflow: hidden;
        }

        .moreLink {
            line-height: 1;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            cursor: pointer;
            .custom-arrow-icon-top:after {
                left: 0;
                transition-delay: 0.15s;
            }

            .custom-arrow-icon-bottom:after {
                right: 0;
                transition-delay: 0s;
            }
        }

        &:active {
            .custom-arrow-icon {
                transform: translateX(3px) translateY(0) scale(0.8);
            }
        }

        .questionDiv {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            h1,
            h1 span {
                font-size: 1.83rem !important;
                font-weight: bold;
            }

            h2,
            h2 span {
                font-size: 1.61rem !important;
                font-weight: bold;
            }

            h3,
            h3 span {
                font-size: 1.44rem !important;
                font-weight: bold;
            }

            h4,
            h4 span {
                font-size: 1.33rem !important;
            }

            h5,
            h5 span {
                font-size: 1.11rem !important;
            }

            p,
            p span {
                font-size: 1rem !important;
            }
        }

        p {
            &.questionStem {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 90%;
            }
        }
    }

    .card-footer {
        background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
        border-top: 1px solid #d8d8d8;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
    }

    .cardBtns {
        position: absolute;
        right: 15px;
        bottom: 48px;

        button {
            border-radius: 100px;
            padding: 6px 8.93px;
        }
    }
}

@media (min-width: 992px) {
    .questionIndexPanel {
        padding-left: 20px;
    }

    .cardCons-question-bank {
        width: calc(50% - 20px);

        .card-body {
            height: 120px;
        }

        .fontSizeSmall {
            .questionDiv {
                -webkit-line-clamp: 4;
            }
        }

        .fontSizeNormal {
            .questionDiv {
                -webkit-line-clamp: 3;
            }
        }

        .fontSizeLarge {
            .questionDiv {
                -webkit-line-clamp: 2;
            }
        }
    }
}

@media (min-width: 1200px) {
    .cardCons-question-bank {
        width: calc(33.33% - 20px);

        .card-body {
            height: 150px;
        }

        .fontSizeSmall {
            .questionDiv {
                -webkit-line-clamp: 5;
            }
        }

        .fontSizeNormal {
            .questionDiv {
                -webkit-line-clamp: 4;
            }
        }

        .fontSizeLarge {
            .questionDiv {
                -webkit-line-clamp: 3;
            }
        }
    }
}

.orderNo1 {
    order: 1;
}

.orderNo2 {
    order: 2;
}

.orderNo3 {
    order: 3;
}

.orderNo4 {
    order: 4;
}

.moduleActivityTable-type {
    width: 128px;

    @media (min-width: 1400px) {
        width: 192px;
    }
}

.evaluationShowMoreIcon {
    display: none;

    span {
        display: none;
    }
}

@media (min-width: 306px) {
    .evaluationShowMoreIcon {
        display: block;
    }
}

@media (min-width: 360px) {
    .evaluationShowMoreIcon {
        span {
            display: block;
        }
    }
}

.dropdownHeading {
    width: 100%;
    background: #f8f8f8;
    padding: 5px 10px;
    color: #717171;
    margin-bottom: 10px;
}

th.whiteSpaceNowrap[tabindex='0'] {
    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255 255 255);
    }
}

.table-border-accommodations {
    border-radius: 4px;
    border: 1px solid #dadada;
    padding: 10px 20px;
}

.height36px {
    height: 36px;
}

.passwordVisible {
    padding: 0;
    margin-left: 0;
    top: 28%;
    right: 2%;
}

.input-group {
    .passwordVisible {
        right: 6%;
        z-index: 3;
    }
}

.testSidebarModal {
    ul {
        padding: 0;
        margin: 0;
        display: grid;

        li {
            list-style: none;
            display: grid;
            align-items: center;
            grid-column-gap: 10px;
            padding: 16px 8px;
            border-left: 4px solid transparent;

            &:not(:first-child) {
                padding-top: 16px;
            }

            a:first-child {
                color: #3c3c43;
            }

            &:hover {
                background: #e7dcf9;
            }

            &:focus-visible {
                background: #e7dcf9;
                outline: 5px solid #1941a6;
                outline-offset: 3px;
            }

            &.active {
                background: #fff;
                border-left: 5px solid #0071be;

                &:hover {
                    background: none;
                }

                a:first-child {
                    color: #3c3c43;
                }
            }

            &.disabled {
                a.disabled {
                    color: #8b8b8f;
                }
            }

            .fa,
            .fas {
                vertical-align: text-bottom;
            }

            a {
                &.disabled {
                    span {
                        color: #6c6c6c;
                    }
                }
            }
        }
    }

    .dropdown-menu-new {
        li {
            display: list-item;
            background: none;
            border: none;
            padding: 0;

            a {
                background: none;
                border-left: 4px solid transparent;
                padding: 16px 8px;

                &:hover {
                    background: #e7dcf9;
                    border-left: 4px solid #ab7cf8;
                }

                &.active {
                    background: #fff;
                    border-left: 5px solid #0071be;

                    &:hover {
                        background: none;
                    }

                    a:first-child {
                        color: #3c3c43;
                    }
                }
            }

            ul {
                padding: 0 10px 0 20px;

                li {
                    display: flex;
                    grid-column-gap: 5px;

                    a,
                    i {
                        color: #3c3c43;
                    }
                }
            }
        }
    }

    .divider {
        margin-left: 0;
        margin-right: 0;
    }

    &.needClarification {
        width: 380px;

        li {
            grid-template-columns: 6fr 5fr;
        }
    }

    &.isReleased {
        li {
            grid-template-columns: 1.5fr 2fr 2fr;
            grid-template-areas: 'leftContent' 'middleContent' 'rightContent';
        }
    }

    .menuText {
        opacity: 1;
        -moz-transition: opacity 0.6s 0.2s ease-out;
        -o-transition: opacity 0.6s 0.2s ease-out;
        -webkit-transition: opacity 0.6s 0.2s ease-out;
        transition: opacity 0.6s 0.2s ease-out;
    }
}

.statusBar {
    -moz-transition: width 0.5s ease-out;
    -o-transition: width 0.5s ease-out;
    -webkit-transition: width 0.5s ease-out;
    transition: width 0.5s ease-out;
    display: inline-block;
    z-index: 0;
    position: fixed;
    height: calc(100% - 10px);
    z-index: 100;

    ul {
        padding: 0;
        margin: 0;
        display: grid;

        li {
            list-style: none;
            display: grid;
            align-items: center;
            grid-column-gap: 10px;
            padding: 16px 8px;
            border-left: 4px solid transparent;

            &:not(:first-child) {
                padding-top: 16px;
            }

            a:first-child {
                color: #3c3c43;
            }

            &:hover {
                background: #e7dcf9;
            }

            &:focus-visible {
                background: #e7dcf9;
                outline: 5px solid #1941a6;
                outline-offset: 3px;
            }

            &.haveAnswered {
                background: #3c7858;
                a:first-child {
                    color: #fff;
                }
            }

            &.active {
                background: #fff;
                border-left: 5px solid #0071be;

                &:hover {
                    background: none;
                }

                a:first-child {
                    color: #3c3c43;
                }
            }

            &.disabled {
                a.disabled {
                    color: #8b8b8f;
                }

                &.haveAnswered {
                    background: #efefef;
                }
            }

            .fa,
            .fas {
                vertical-align: text-bottom;
            }
        }
    }

    .dropdown-menu-new {
        li {
            display: list-item;
            background: none;
            border: none;
            padding: 0;

            a {
                background: none;
                border-left: 4px solid transparent;
                padding: 16px 8px;

                &:hover {
                    background: #e7dcf9;
                    border-left: 4px solid #ab7cf8;
                }

                &.active {
                    background: #fff;
                    border-left: 5px solid #0071be;

                    &:hover {
                        background: none;
                    }

                    a:first-child {
                        color: #3c3c43;
                    }
                }
            }

            ul {
                padding: 0 10px 0 20px;

                li {
                    display: flex;
                    grid-column-gap: 5px;

                    a,
                    i {
                        color: #3c3c43;
                    }
                }
            }
        }
    }

    .divider {
        margin-left: 0;
        margin-right: 0;
    }
    &.needClarification {
        width: 380px;

        li {
            grid-template-columns: 6fr 5fr;
        }
    }

    &.isReleased {
        li {
            grid-template-columns: 1.5fr 2fr 2fr;
            grid-template-areas: 'leftContent' 'middleContent' 'rightContent';
        }
    }

    .menuText {
        opacity: 1;
        -moz-transition: opacity 0.6s 0.2s ease-out;
        -o-transition: opacity 0.6s 0.2s ease-out;
        -webkit-transition: opacity 0.6s 0.2s ease-out;
        transition: opacity 0.6s 0.2s ease-out;
    }

    #toggleIcon {
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    .rotate {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    &.sidebar--Collapse {
        width: auto;
        max-width: 186px;
        -moz-transition: width 0.6s ease-out;
        -o-transition: width 0.6s ease-out;
        -webkit-transition: width 0.6s ease-out;
        transition: width 0.6s ease-out;

        .menuText {
            opacity: 0;
            -moz-transition: opacity 0.3s ease-out;
            -o-transition: opacity 0.3s ease-out;
            -webkit-transition: opacity 0.3s ease-out;
            transition: opacity 0.3s ease-out;
            position: absolute;
        }
    }

    .panel {
        border: none;
        margin: 0;

        .panel-heading,
        .panel-body {
            padding: 16px 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .panel-body {
            background: #f7faff;
            height: calc(100% - 36px);

            &.panel-body-closed {
                p {
                    padding-left: 8px;
                    padding-right: 8px;
                }

                li {
                    grid-template-columns: auto;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.test-sideBar-panel-body {
                overflow-x: auto;
            }
        }
    }

    .sidebar-navigator-V2 {
        ul {
            display: block;

            li {
                display: block;
                border-left: 0;

                &:hover {
                    background: none;
                }

                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    & > div {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        label {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: 100%;
                            cursor: pointer;
                        }

                        &:first-child {
                            &.withCounter {
                                max-width: calc(100% - 128px);
                            }

                            max-width: calc(100% - 20px);
                        }

                        &:only-child {
                            max-width: 100%;
                        }
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;

                        label {
                            cursor: pointer;
                        }
                    }
                }

                & ~ .sub-menus {
                    li {
                        margin-left: 35px;
                        padding: 0 8px;

                        & > .sub-stems {
                            li {
                                margin-left: 30px;
                                padding: 16px 8px;

                                a {
                                    & > div {
                                        &:first-child {
                                            max-width: calc(100% - 100px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                & ~ .standalone-sub-stems {
                    li {
                        margin-left: 35px;
                        padding: 16px 8px;
                    }
                }

                .students_criteria {
                    display: flex;
                    align-items: center;

                    label {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        cursor: pointer;
                    }
                }

                &.active {
                    background: #ebf3fe;
                }
            }

            & > li {
                &:not(:last-of-type) {
                    & > a {
                        & > div {
                            & > .step-counter {
                                &::after {
                                    height: 32px;
                                }

                                &.withDynamicMenuHeight {
                                    &::after {
                                        min-height: 32px;
                                        height: var(--dynamic-height);
                                    }
                                }
                            }
                        }
                    }

                    & > div {
                        & > .step-counter {
                            &.withSubDynamicHeight {
                                &::after {
                                    min-height: 32px;
                                    height: var(--sub-dynamic-height);
                                }
                            }
                        }
                    }
                }
            }

            &.standalone-sub-stems {
                li {
                    a {
                        div {
                            &:first-child {
                                max-width: calc(100% - 100px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .statusBar {
        width: 240px;
    }

    .evaluationV2-test {
        .statusBar {
            width: 350px;
        }
    }
}

.md-lower {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .form-search,
    button,
    .form-dropdown {
        // margin-top: 5px;
        // margin-bottom: 5px;

        &.margin0 {
            margin: 0;
        }
    }

    .new-custom-search-select {
        button {
            margin: 0;
            height: 100%;
        }
    }

    select {
        height: 100%;
    }
}

.slider-new {
    display: flex;
    align-items: center;
    border-radius: 8px;

    &.hasButtons {
        border-radius: 8px;
        padding: 5px;
        outline: 4px solid transparent;
    }

    &:focus-within {
        box-shadow: none;
        outline: 4px solid #1941a6;
    }

    .slider-content {
        display: flex;
        width: 100%;
        position: relative;

        input[type='number'] {
            text-align: center;
            position: absolute;
            top: -45px;
            width: 64px;

            &:focus,
            &.focus {
                & ~ input[type='range'] {
                    &::-webkit-slider-thumb {
                        box-shadow: 0 0 0 10px #dac7fb;
                    }

                    &::-moz-range-thumb {
                        box-shadow: 0 0 0 10px #dac7fb;
                    }

                    &::-ms-thumb {
                        box-shadow: 0 0 0 10px #dac7fb;
                    }
                }
            }

            &:focus-visible {
                & ~ input[type='range'] {
                    &::-webkit-slider-thumb {
                        outline-offset: 6px;
                        outline: 4px solid #1941a6;
                    }

                    &::-moz-range-thumb {
                        outline-offset: 6px;
                        outline: 4px solid #1941a6;
                    }

                    &::-ms-thumb {
                        outline-offset: 6px;
                        outline: 4px solid #1941a6;
                    }
                }
            }
        }

        .slider-label {
            text-align: center;
            position: absolute;
            top: -30px;
            background: rgba(34, 34, 34, 0.9);
            color: #fff;
            border-radius: 8px;
            padding: 4px;
            width: 60px;
            overflow: hidden;
        }

        .range-color {
            position: absolute;
            left: 10px;
            top: 20px;
            background: #761fbb;
            height: 8px;
            border-radius: 8px;

            &.disabled {
                background: #8b8b8f;
            }
        }

        &.rangeOnly {
            .range-color {
                z-index: 1;
            }
        }

        input[type='range'] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            padding: 20px 10px;
            background-color: transparent;

            &:hover {
                cursor: grab;
            }

            &:active {
                cursor: grabbing;
            }

            &:disabled {
                cursor: not-allowed;
            }

            &::-webkit-slider-runnable-track {
                border-radius: 8px;
                width: 100%;
                height: 8px;
                background-color: #efefef;
            }

            &::-moz-range-track {
                border-radius: 8px;
                width: 100%;
                height: 8px;
                background-color: #efefef;
            }

            &::-ms-track {
                box-sizing: border-box;
                border: none;
                border-radius: 8px;
                width: 100%;
                height: 8px;
                background-color: #efefef;
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background: #fff;
                border: 8px solid #761fbb;
                transition: box-shadow 0.2s;
                transform: translateY(-12px);
            }

            &::-moz-range-thumb {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background: #fff;
                border: 8px solid #761fbb;
                transition: box-shadow 0.2s;
            }

            &::-ms-thumb {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background: #fff;
                border: 8px solid #761fbb;
                transition: box-shadow 0.2s;
                transform: translateY(-12px);
            }

            &::-moz-range-progress {
                border-radius: 8px;
                height: 8px;
                background-color: #761fbb;
            }

            &::-ms-fill-lower {
                border-radius: 8px;
                height: 8px;
                background-color: #761fbb;
            }

            &::-ms-fill-upper {
                border-radius: 8px;
                height: 8px;
                background-color: #efefef;
            }

            &:focus,
            &.focus {
                &::-webkit-slider-thumb {
                    box-shadow: 0 0 0 10px #dac7fb;
                }

                &::-moz-range-thumb {
                    box-shadow: 0 0 0 10px #dac7fb;
                }

                &::-ms-thumb {
                    box-shadow: 0 0 0 10px #dac7fb;
                }
            }

            &:focus-visible {
                &::-webkit-slider-thumb {
                    outline-offset: 6px;
                    outline: 4px solid #1941a6;
                }

                &::-moz-range-thumb {
                    outline-offset: 6px;
                    outline: 4px solid #1941a6;
                }

                &::-ms-thumb {
                    outline-offset: 6px;
                    outline: 4px solid #1941a6;
                }
            }

            &:hover,
            &.hover {
                &::-webkit-slider-thumb {
                    box-shadow: 0 0 0 10px #e7dcf9;
                }

                &::-moz-range-thumb {
                    box-shadow: 0 0 0 10px #e7dcf9;
                }

                &::-ms-thumb {
                    box-shadow: 0 0 0 10px #e7dcf9;
                }
            }

            &:active,
            &.active {
                &::-webkit-slider-thumb {
                    box-shadow: 0 0 0 10px #cbabfc;
                }

                &::-moz-range-thumb {
                    box-shadow: 0 0 0 10px #cbabfc;
                }

                &::-ms-thumb {
                    box-shadow: 0 0 0 10px #cbabfc;
                }
            }

            &:disabled {
                &:hover,
                &:active,
                &:focus {
                    &::-webkit-slider-thumb {
                        box-shadow: none;
                    }

                    &::-moz-range-thumb {
                        box-shadow: none;
                    }

                    &::-ms-thumb {
                        box-shadow: none;
                    }
                }

                &::-webkit-slider-runnable-track {
                    background-color: #efefef;
                }

                &::-webkit-slider-thumb {
                    background-color: #fff;
                    border-color: #8b8b8f;
                }

                &::-moz-range-track {
                    background-color: #efefef;
                }

                &::-moz-range-progress {
                    background-color: #8b8b8f;
                }

                &::-moz-range-thumb {
                    background-color: #fff;
                    border-color: #8b8b8f;
                }

                &::-ms-fill-lower {
                    background-color: #efefef;
                }

                &::-ms-fill-upper {
                    background-color: #efefef;
                }

                &::-ms-thumb {
                    background-color: #fff;
                    border-color: #8b8b8f;
                }
            }

            &::-moz-focus-outer {
                border: none;
            }
        }

        &.rangeOnly {
            height: 45px;

            input[type='range'] {
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                box-sizing: border-box;

                &::-webkit-slider-thumb {
                    z-index: 2;
                    position: relative;
                }

                &::-moz-range-thumb {
                    z-index: 2;
                    position: relative;
                }

                &::-ms-thumb {
                    z-index: 2;
                    position: relative;
                }
            }
        }
    }
}

.modifyCreateQuestionsModal {
    hr {
        border-top-color: #717171;
    }

    .modal-body {
        padding: 20px 0;

        & > div > div {
            padding: 0 20px;
        }

        & > div:last-child {
            padding: 0 20px;
        }

        .alert {
            padding: 5px 10px;
        }
    }
}

.two-column-grid-activity-setup {
    display: block;
}

.two-column-grid-dashboard-evaluation {
    display: block;

    .left-column,
    .right-top-header {
        background: #23356d;
        padding: 12px 8px;
        color: #fff;

        button {
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            color: #fff;

            &.active {
                background: #fff;
                color: #23356d;
            }
        }
    }

    .left-column {
        h3 {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .right-top-header {
        h3 {
            margin: 0;
        }
    }
}

.three-column-grid-dashboard-evaluation {
    display: block;

    .studentNameList {
        background: rgba(35, 53, 109, 0.8);
        text-align: center;
        padding: 12px 8px;
        color: #fff;

        h3 {
            margin-top: 0;
            margin-bottom: 20px;
        }

        button {
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            text-overflow: ellipsis;
            color: #fff;

            &.active {
                background: #fff;
                color: #23356d;
            }
        }
    }

    .student-answers-left-column,
    .student-answers-right-column {
        border: 1px solid #dfdfdf;

        &-header {
            padding: 12px 8px;

            h3 {
                margin: 0;
            }

            border-bottom: 1px solid #717171;
        }

        &-content {
            padding: 8px;
        }
    }

    .student-answers-left-column {
        border-right: 0;
    }
}

.set-up-columns {
    display: block;

    & > * {
        border: 1px solid #d8d8d8;
        padding: 16px;

        &:first-child {
            border-right: 0;
        }

        &:last-child {
            border-left: 0;
        }
    }
}

@media (min-width: 768px) {
    .two-column-grid-activity-setup {
        display: grid;
        grid-template-columns: minmax(0, 0.4fr) minmax(0, 1fr);

        & > * {
            padding: 1rem;

            &.paddingTop0 {
                padding-top: 0;
            }

            &.paddingRight0 {
                padding-right: 0;
            }
        }
    }

    .two-column-grid-dashboard-evaluation {
        display: grid;
        grid-template-columns: minmax(0, 0.1fr) minmax(0, 1fr);
    }

    .three-column-grid-dashboard-evaluation {
        display: grid;
        grid-template-columns: minmax(0, 0.15fr) minmax(0, 0.15fr) minmax(0, 1fr);
    }

    .set-up-columns {
        display: grid;
        grid-template-columns: minmax(0, 1.14fr) minmax(0, 1fr) minmax(0, 1fr);
    }
}

.accordion-item-primary,
.accordion-item-light-primary-dashboard {
    border-radius: 8px;
    border: 1px solid #717171;

    .accordion-header {
        background: #23356d;
        color: #222;
        padding: 16px 8px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        button {
            color: #fff;
            padding: 0 8px;

            &.paddingLeft0 {
                padding-left: 0;
            }

            label {
                font-size: 1.111rem;
            }

            p {
                font-weight: normal;
            }
        }

        label {
            font-size: 1.111rem;
        }

        p {
            font-weight: normal;
        }

        &-description {
            padding-top: 5px;
            color: #fff;

            .questionDiv {
                white-space: break-spaces;
            }
        }
    }

    .accordion-content {
        & > div {
            padding: 16px 8px;
        }
    }

    &.borderRadius0 {
        border-radius: 0;

        &.borderNone {
            border: 0;
        }

        .accordion-header {
            border-radius: 0;
        }
    }

    .accordion-button {
        &.collapsed {
            .fa-chevron-up {
                transform: rotate(180deg);
            }
        }

        .fa-chevron-up {
            transform: rotate(0deg);
        }
    }
}

.accordion-item-light-primary-dashboard {
    border-color: #dfdfdf;

    .accordion-header {
        background: #ebf3fe;
        padding: 8px;

        button {
            color: #3c3c43;
            padding: 0;
            font-size: 0.889rem;
        }
    }
}

.question-stem-card-container {
    border: 1px solid #dfdfdf;

    .question-stem-card-header {
        padding: 8px;
        background: #dbeafe;
        display: flex;
        align-items: center;
    }

    .question-stem-card-content {
        background: #fff;
        padding: 8px;
    }
}

.border-right-table-column-outline {
    border-right: 1px solid #dfdfdf;
}

.border-left-table-column-outline {
    border-left: 1px solid #dfdfdf;
}

.numberInput-step-new {
    display: inline-flex;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        border-radius: 8px;
    }

    input[type='number'] {
        border-color: #3c3c43;
        width: 100px;
    }
}

.pointDistributionTable {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #717171;

    table {
        border: 0;
        border-radius: 8px;
        border-collapse: collapse;
        background: #efefef;

        thead {
            tr {
                background: #ebf3fe;

                th {
                    border-right: 0;
                }
            }
        }

        tbody {
            tr {
                border-top: 1px solid #717171;

                td {
                    border-color: #dfdfdf;

                    &.error {
                        background: #fdecec;

                        .fa-circle-exclamation {
                            color: #ba2929;
                        }
                    }
                }
            }
        }
    }
}

.createQuestionsModalV2 {
    .modal-footer {
        background: #ebf3fe;
        padding: 16px;
    }
}

.create {
    .rightPanel {
        .header {
            font-weight: bold;
            /* text-transform: uppercase; */
            color: #717171;
            margin-bottom: 10px;
        }

        .content {
            background: #fff;
            /* border: 1px solid #dfe3e9; */
            border: 1px solid #717171;
            padding: 15px;
            color: #717171;

            .header {
                margin-bottom: 5px;
            }

            & > div {
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.manage-question-sidebar {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 0;

    .x_content {
        max-height: 70vh;
        overflow: inherit !important;
        padding: 0 !important;
    }

    .sidebar-sub-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        top: 0;
        padding: 16px;
        box-shadow: 0px 2px 7px 0px rgba(60, 60, 67, 0.5);
    }

    .sidebar-content {
        max-height: calc(70vh - 66px);
        overflow: hidden;
        overflow-y: auto;
        padding: 16px;
    }

    .card-new {
        border-radius: 8px;
        border: 1px solid #efefef;

        .card-new-header {
            padding: 8px;
            display: inline-flex;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #dfdfdf;

            &.border0 {
                border: 0;
            }

            .btn {
                padding: 8px 8px 8px 0;
            }

            .checkboxField {
                width: calc(100% - 28px);

                label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .card-new-content {
            padding: 16px 8px;
            display: flex;
            align-items: center;

            label {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        &.active {
            border-color: #717171;

            .card-new-header {
                background: #23356d;
                color: #fff;

                button {
                    color: #fff;
                }
            }
        }
    }
}

.rating-wrapper-column {
    display: flex;
    flex-wrap: wrap;

    & > div {
        width: calc(100% - 150px);

        & + div {
            width: 150px;
            min-width: 150px;
            padding: 16px 0 16px 24px;
        }
    }
}

.rating-flex-layout {
    display: flex;
    max-width: 100%;
    overflow: auto;
    column-gap: 10px;

    & > div {
        min-width: 200px;
        max-width: 250px;
    }

    .rating-scale-wrapper {
        text-align: center;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #717171;

        .radioField {
            display: inline-block;

            input[type='radio'] {
                margin-right: 0;
                position: inherit;
            }
        }

        .rating-label {
            width: 100%;
        }

        label,
        p {
            font-size: 0.88rem;
        }

        .rating-label ~ label {
            width: 100%;
            min-height: 23px;
        }
    }
}

.hidden-md.hidden-lg {
    .rating-scale-wrapper {
        display: flex;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #717171;
    }
}

.modal-countdown-number::after {
    content: 'Redirecting';
    width: 1ch;
    animation: countdown 3s step-end;
}

@keyframes countdown {
    0% {
        content: 'Redirecting in 3s';
    }
    33% {
        content: 'Redirecting in 2s';
    }
    67% {
        content: 'Redirecting in 1s';
    }
    100% {
        content: '0';
    }
}

.sideBarStatusV2 {
    display: flex;

    .main {
        -moz-transition: margin-left 0.55s ease-out;
        -o-transition: margin-left 0.55s ease-out;
        -webkit-transition: margin-left 0.55s ease-out;
        transition: margin-left 0.55s ease-out;
    }

    .main--slide {
        -moz-transition: margin-left 0.6s ease-out;
        -o-transition: margin-left 0.6s ease-out;
        -webkit-transition: margin-left 0.6s ease-out;
        transition: margin-left 0.6s ease-out;
    }

    .statusBar {
        &.sidebar--Collapse {
            max-width: 198px;
        }

        .test-sideBar-panel-body {
            button {
                &:disabled {
                    color: #6c6c6c;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .sideBarStatusV2 {
        .main,
        .main--slide {
            margin-left: 0;
            width: 100%;
        }
    }
}

.test_topBar_v2 {
    position: fixed;
    z-index: 200;
    width: 100%;
    top: 0;

    .new_topBar_content {
        background: #23356d;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 16px;
        color: #fff;

        &.d-block {
            display: block;
        }

        .test_topBarContent {
            flex: 1;
        }

        .test_title {
            font-size: 1.111rem;
            font-weight: bold;
            text-transform: capitalize;
            color: #fff;
            text-align: left;
        }

        .timer_Div {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            font-weight: bold;
            line-height: 1;

            .time {
                .number-div {
                    background: #11204f;
                    padding: 6px 12px 1px 12px;
                    border-radius: 8px;
                    line-height: 1.2;

                    &:last-child {
                        margin-left: 5px;

                        &.margin0 {
                            margin-left: 0;
                        }

                        &.padding8bottom4 {
                            padding: 4px 10px 2px 10px;
                        }
                    }

                    &-success {
                        color: #34d399;
                    }

                    &-warning {
                        color: #f59e0b;
                    }

                    &-danger {
                        color: #ca3333;
                    }

                    &.flex {
                        flex-wrap: inherit;
                    }
                }

                label {
                    text-align: center;
                    color: #fff;
                    font-weight: normal;
                    display: block;
                    margin-top: 10px;
                }

                .colon {
                    color: #fff;
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .test_topBar_v2 {
        .timer_Div {
            font-size: 40px;
        }

        .colon {
            padding: 0 10px;
        }
    }
}

@media (max-width: 991px) {
    .test_topBar_v2 {
        .timer_Div {
            font-size: 14px;
        }

        .colon {
            padding: 0 5px;
        }

        .time .number-div {
            padding: 6px 10px 5px;
            font-size: 0.889rem;
        }
    }
}

.top-bar-activity-details-container {
    /*position: fixed;
    z-index: 99;
    width: 100%;*/

    .top-bar-activity-details {
        padding: 5px 16px;
        background: #dbeafe;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 2px solid #23366d;

        a {
            color: #005996;
        }
    }

    .top-bar-current-evaluate {
        padding: 5px 16px;
        background: #ffefe0;
        border-bottom: 2px solid #e38930;

        label {
            font-size: 1rem;
        }

        .colorSelf {
            color: #894812;
        }
    }
}

.evaluationV2-test,
.evaluationV2-report {
    .topBarActivityDetails-banner-container {
        height: auto;
    }

    .card-new {
        border-radius: 8px;
        border: 1px solid transparent;
        max-width: 262px;
        overflow: hidden;

        .card-new-header {
            padding: 8px;

            label {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .card-new-content {
            & > div {
                padding: 16px 8px;

                &:not(:first-child) {
                    border-top: 1px solid #dfdfdf;
                }
            }

            li {
                border: 0;

                button {
                    padding: 0;

                    & > div {
                        label {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: calc(100% - 41px);
                            padding-top: 5px;
                        }
                    }
                }
            }

            .panel-body {
                padding: 0;
            }
        }
    }

    .x_title {
        padding: 16px !important;
        background: #23356d;
        color: #fff;
        display: block;

        h3 {
            margin-top: 0;
        }
    }

    .white-header {
        .x_title {
            background: #fff;
            color: #000;
        }
    }

    .statusBar {
        .panel-body {
            background: #ebf3fe;
        }

        .panel-heading {
            background-image: none;
            background: #ebf3fe;
        }
    }
}

.evaluation-panel-content {
    & > div {
        padding: 16px;

        &:first-child {
            background: #dbeafe;

            .tableStatusTag {
                margin-top: 10px;

                &.margin0 {
                    margin: 0;
                }
            }
        }

        & > .evaluation-sub-header {
            background: #dbeafe;
            padding: 10px 16px 16px;
        }
    }

    .tableStatusTag {
        display: inline-block;
        padding: 8px 16px 6px;
        margin-bottom: 10px;
        white-space: nowrap;

        &.margin0 {
            margin: 0;
        }
    }
}

.stepper-container {
    max-width: 100%;
    overflow: auto;
    padding-top: 8px;
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    margin-bottom: 20px;

    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        min-width: 100px;
        max-width: 120px;
        padding: 5px;
        font-weight: normal;
        background: none;
        border: 0;

        label {
            font-weight: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
        }

        @media (max-width: 768px) {
            font-size: 12px;
        }

        &::before {
            position: absolute;
            content: '';
            border-bottom: 3px solid #efefef;
            width: 100%;
            top: 20px;
            left: -50%;
            z-index: 2;
        }

        &::after {
            position: absolute;
            content: '';
            border-bottom: 3px solid #efefef;
            width: 100%;
            top: 20px;
            left: 50%;
            z-index: 2;
        }

        &.justifyContentFlexStart {
            justify-content: flex-start;

            label {
                overflow: inherit;
                white-space: inherit;
            }
        }

        &.active,
        &.completed {
            &::before {
                border-bottom: 3px solid #0071be;
            }

            &::after {
                border-bottom: 3px solid #0071be;
            }
        }

        &:first-child::before,
        &:last-child::after {
            content: none;
        }

        .step-counter {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #efefef;
            border: 2px solid #222;
            color: #222;
            margin-bottom: 6px;

            .alert-icon {
                position: absolute;
                top: -6px;
                right: -6px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                width: 20px;
                height: 20px;
                background: #ca3333;
                color: #fff;
                border-radius: 50%;
            }
        }

        &:disabled,
        &.disabled {
            &.hasToolTip {
                pointer-events: inherit;
            }

            &:hover,
            &:focus {
                background-color: inherit;
                border: none;
                box-shadow: none;
                color: #6c6c6c;

                &.hasToolTip {
                    pointer-events: inherit;
                }
            }

            color: #6c6c6c;

            .step-counter {
                background: #6c6c6c;
                border-color: #6c6c6c;
                color: #fff;
            }
        }

        .step-marker-container {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 6px;
            position: relative;
            z-index: 5;

            .step-marker {
                transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
                transform-origin: 50% 50%;
                border-radius: 50% 50% 50% 0;
                width: 35px;
                height: 35px;
                background: #0071be;
                position: absolute;
                top: -2px;

                &:after {
                    content: '';
                    width: 15px;
                    height: 15px;
                    background: #ffffff;
                    position: absolute;
                    border-radius: 50%;
                    top: 12px;
                    left: 8px;
                }
            }

            .alert-icon {
                position: absolute;
                top: -6px;
                right: -6px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                width: 20px;
                height: 20px;
                background: #ca3333;
                color: #fff;
                border-radius: 50%;
            }
        }

        .step-completed {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #3c7858;
            color: #fff;
            margin-bottom: 6px;
        }

        &.active {
            .step-counter {
                background: #0071be;
                border-color: #0071be;
                color: #fff;
            }

            label {
                font-weight: bold;
            }
        }

        &.completed {
            &:not(:first-of-type) {
                &::before {
                    position: absolute;
                    content: '';
                    border-bottom: 2px solid #0071be;
                    width: 100%;
                    top: 20px;
                    right: 0;
                    z-index: 3;
                }
            }
        }
    }
}

.evaluationV2-report {
    .test_topBar_v2 {
        .new_topBar_content {
            background: #fff;
            color: #222;
        }
    }

    .x_panel {
        border: none !important;
        box-shadow: none !important;
        margin-bottom: 0 !important;
    }

    .x_title {
        padding: 16px !important;
    }

    & > div {
        &:last-child {
            padding-bottom: 60px;
            background: #fff;
        }
    }

    .report-card {
        border: 1px solid #c4c4c4;
        border-radius: 8px;
        overflow: hidden;

        & > div {
            padding: 8px;
            text-align: center;
            font-weight: bold;

            &:first-child {
                background: #23356d;
                color: #fff;
            }

            h4 {
                font-weight: bold;
                margin: 0;
            }
        }
    }

    .evaluation-panel-content {
        & > div {
            padding: 0;

            &:first-child {
                background: none;
            }

            & > div {
                padding: 16px;

                &:first-child {
                    background: #dbeafe;
                }
            }
        }
    }

    .badge_profile {
        background: #23356d;
    }

    .colorSelf {
        .badge_profile {
            background: #894812;
        }
    }
}

.ratingV2-report-columns,
.openendedV2-report-columns {
    display: block;

    &:not(:first-child) {
        margin-top: 16px;
    }
}

@media (min-width: 768px) {
    .ratingV2-report-columns {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    .openendedV2-report-columns {
        display: grid;
        grid-template-columns: minmax(0, 0.5fr) minmax(0, 1fr);
    }
}

@media (max-width: 768px) {
    .ratingV2-report-columns,
    .openendedV2-report-columns {
        & > div {
            &:not(:first-child) {
                padding-left: 40px;
            }
        }
    }
}

@media (min-width: 551px) {
    .evaluationV2-report > div {
        &:last-child {
            padding-bottom: 36px;
        }
    }
}

@media (max-width: 260px) {
    .report-card {
        width: 100%;
    }
}

.openendedV2-report-table {
    thead {
        tr {
            background-image: none;
            background: #23356d;
            color: #fff;
        }
    }

    .add-border {
        border-right: 1px solid #ddd;
    }
}

.colorSelf {
    color: #894812;
}

.pointDistribution-report-columns {
    display: block;

    & > div {
        &:last-child {
            margin-top: 20px;
        }
    }

    .pointDistribution-report-columns-name {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:first-child) {
            margin-top: 20px;
        }

        & > div {
            &:first-child {
                width: calc(100% - 30px);

                p {
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@media (min-width: 436px) {
    .pointDistribution-report-columns {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: 10px;

        & > div {
            &:last-child {
                margin-top: 0;
            }
        }
    }
}

.circular-points {
    display: inline-block;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    padding: 5px 5px 2px 5px;
    background: #ca3333;
    color: #fff;
    text-align: center;
    box-sizing: content-box;
    white-space: nowrap;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        padding-top: 100%;
        height: 0;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }
}

.sidebar-stepper {
    .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background: #fff;
        color: #222;
        border: 1px solid #222;
        font-size: 0.7rem;
        line-height: 0.8;
        font-weight: normal;
        margin-right: 6px;

        .alert-icon {
            position: absolute;
            top: 0;
            right: -2px;
            width: 10px;
            height: 10px;
            background: #ca3333;
            border-radius: 50%;
        }

        &-completed {
            background: #3c7858;
            color: #fff;
            border-color: #3c7858;
        }

        &-current {
            background: #fff;
            color: #0071be;
            border-color: #0071be;
        }

        &-incomplete {
            background: #0071be;
            color: #fff;
            border-color: #0071be;
        }

        &::after {
            position: absolute;
            content: '';
            z-index: 2;
            border-left: 2px solid #222;
            height: 0;
            top: 29px;
        }

        &.step-counter-completed,
        &.step-counter-incomplete {
            &::after {
                border-color: #0071be;
            }
        }
    }

    .panel-body.test-sideBar-panel-body {
        padding: 0;
        height: 100%;
        overflow-x: inherit;
    }

    .test-sideBar-panel-body {
        .sidebar-filtering {
            padding: 16px 8px;
        }

        &-content {
            padding: 4px 8px 16px;
            height: calc(100% - 266px);
            overflow-x: auto;
            background: #fff;
        }
    }
}

#chooseACriteria {
    width: 100%;

    div {
        display: flex;
        align-items: center;
        width: calc(100% - 20px);

        span {
            width: 24px;
            margin-right: 8px;
            overflow: inherit;
        }

        label {
            font-weight: normal;
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }
    }
}

#criteriaListingDashboard {
    label {
        font-weight: normal;
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }
}

.mobileSideBar {
    display: none;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .statusBar {
        width: 350px;
    }

    @media (max-width: 930px) {
        .sidebar-stepper {
            .test-sideBar-panel-body-content {
                height: calc(100% - 126px);
            }
        }
    }
}

.buttons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 9em);
    gap: 10px;
}

.newQuestionModalTable {
    table {
        box-shadow: none;

        tr {
            background: none;
            background-image: none;
            box-shadow: none;
        }

        td,
        th {
            padding: 0;
            border: 0;
        }

        td {
            &:nth-child(1) {
                width: 34px;
            }

            &:nth-child(2) {
                width: 14px;
            }

            &:nth-child(3) {
                width: 628px;
                padding-right: 20px;
            }

            &:nth-child(4) {
                width: 55px;
                padding-right: 10px;
            }

            &:nth-child(5) {
                width: 55px;
                padding-right: 10px;
            }
        }

        .notFirstRow {
            td {
                padding-top: 20px;
            }
        }

        input[data-required] {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 20 20" height="48px" viewBox="0 0 20 20" width="48px" fill="%23ca3333"><rect fill="none" height="20" width="20"/><polygon points="16.81,7.8 15.31,5.2 11.5,7.4 11.5,3 8.5,3 8.5,7.4 4.69,5.2 3.19,7.8 7,10 3.19,12.2 4.69,14.8 8.5,12.6 8.5,17 11.5,17 11.5,12.6 15.31,14.8 16.81,12.2 13,10"/></svg>');
            background-size: 0.5em;
            background-position: right 10px top 10px;
            background-repeat: no-repeat;
        }
    }
}

.bg-light-blue {
    background: #dbeafe;
}

.questionFilterColumn {
    & > div {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .btn {
        margin-left: 0;
        width: 100%;
        &:first-child {
            margin-bottom: 10px;
        }
    }
}

.report-table-header {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 15px 20px;
    border-bottom: 1px solid #d8d8d8;
}

div {
    &:focus-visible {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        border-radius: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }
}

.QuestionBankFilterByDropdownField {
    label {
        font-weight: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: calc(100% - 12px);
    }
}

.QuestionBankFilterByDropdown {
    label {
        font-weight: normal;
    }
}
.template-cards {
    border-radius: 8px;
    width: 100%;
    border: 1px solid #d8d8d8;

    .card-body {
        padding: 16px;

        h3 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin-top: 0;
        }

        p {
            color: #8a8a8e;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 1rem;
        }
    }

    .card-footer {
        border-top: 1px solid #8b8b8f;
        padding: 16px;

        p {
            font-size: 1rem;
        }
    }
}

.listing-template {
    button {
        display: block;
        padding: 0;
        text-align: left;
        font-weight: normal;

        &.template-cards {
            border: 4px solid #d8d8d8;
            position: relative;
        }

        &:active,
        &.selected {
            border: 4px solid #007aff;
        }

        .selectedCard {
            position: absolute;
            top: -4px;
            left: -4px;
            background: #007aff;
            padding: 5px 10px;
            color: #fff;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}

.borderRadius8 {
    border-radius: 8px;
}

.dropdown-backdrop {
    display: none;
    position: inherit;
}

.zIndex1 {
    z-index: 1;
}

.zIndex2 {
    z-index: 2;
}

.input-group {
    .form-control {
        &.zIndex1 {
            z-index: 1;
        }
    }
}

.limit4Lines {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.mcqStatisticsBarChart {
    border: 1px solid #d8d8d8;

    .panel-header {
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1;
        border-bottom: 1px solid #d8d8d8;
        -webkit-box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
        box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
    }

    table {
        &.barChart {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            height: 330px;
            max-height: 330px;
            position: relative;
            overflow: auto;
            border: none;
            box-shadow: none;

            .bars {
                display: flex;
                flex-direction: column;
                background: #989b9c;
                display: block;
                width: 30px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                padding: 0;
                max-height: 184px;

                &.correct {
                    background: #3c7858;
                }

                &.wrong {
                    background: #ca3333;
                }

                td {
                    text-align: center;
                    display: block;
                    margin-right: 0.5em;
                    line-height: 0em;

                    &::after {
                        content: '';
                        height: 1px;
                        width: 100%;
                        background: black;
                        position: absolute;
                    }
                }
            }

            tr {
                flex-grow: 1;

                &:nth-child(6n + 1) .btn {
                    margin: 0;
                    background: #e48b3a;
                    color: #fff;
                    max-width: 100%;
                    display: flex;
                    margin-top: 10px;

                    label {
                        max-width: calc(100% - 28px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &:nth-child(6n + 2) .btn {
                    margin: 0;
                    background: #4e669b;
                    color: #fff;
                    max-width: 100%;
                    display: flex;
                    margin-top: 10px;

                    label {
                        max-width: calc(100% - 28px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &:nth-child(6n + 3) .btn {
                    margin: 0;
                    background: #81359f;
                    color: #fff;
                    max-width: 100%;
                    display: flex;
                    margin-top: 10px;

                    label {
                        max-width: calc(100% - 28px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &:nth-child(6n + 4) .btn {
                    margin: 0;
                    background: #428459;
                    color: #fff;
                    max-width: 100%;
                    display: flex;
                    margin-top: 10px;

                    label {
                        max-width: calc(100% - 28px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &:nth-child(6n + 5) .btn {
                    margin: 0;
                    background: #bfa677;
                    color: #fff;
                    max-width: 100%;
                    display: flex;
                    margin-top: 10px;

                    label {
                        max-width: calc(100% - 28px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                &:nth-child(6n + 6) .btn {
                    margin: 0;
                    background: #ba4747;
                    color: #fff;
                    max-width: 100%;
                    display: flex;
                    margin-top: 10px;

                    label {
                        max-width: calc(100% - 28px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }

            .body {
                display: flex;
                flex-grow: 1;
                max-height: 200px;
                border-bottom: 2px solid #d8d8d8;

                tr {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    flex-grow: 1;
                    flex-basis: 0;
                    margin: 0 2% 0 0;
                    position: relative;
                    width: 150px;
                    max-height: 200px;

                    &:first-child {
                        margin-left: 2%;
                    }
                }
            }

            .record {
                text-align: center;
                position: absolute;
                top: 200px;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #0071be;
                font-weight: bold;
                line-height: 1;
                width: 150px;
                padding: 10px;
            }

            .percentage {
                position: absolute;
                font-weight: bold;
                font-size: 16px;
                line-height: 1;
            }
        }
    }
}

.collapsingQuestion {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    overflow: hidden;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
    }

    > .btn:first-child {
        padding: 10px;
        background: #f2f4f7;
        width: 100%;
        justify-content: flex-start;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &[aria-expanded='true']::after {
            font-family: FontAwesome;
            content: '\f0d8';
            margin-left: 8px;
        }

        &[aria-expanded='false']::after {
            font-family: FontAwesome;
            content: '\f0d7';
            margin-left: 8px;
        }
    }

    &.correctOption {
        border-color: #3c7858;
        background: #fff;

        .btn:first-child {
            background: #f3fffd;

            &[aria-expanded='true'] {
                border-bottom: 1px solid #3c7858;
            }
        }
    }

    &.newCorrectOption {
        border-color: #497970;
        background: #fff;

        .btn:first-child {
            background: #eef4f3;

            &[aria-expanded='true'] {
                border-bottom: 1px solid #497970;
            }
        }
    }

    .collapse {
        font-size: 1.111rem;
    }
}

.borderTopLeftRadius0 {
    border-top-left-radius: 0;
}

.borderBottomLeftRadius0 {
    border-bottom-left-radius: 0;
}

.borderTopRightRadius0 {
    border-top-right-radius: 0;
}

.borderBottomRightRadius0 {
    border-bottom-right-radius: 0;
}

.discussionOption {
    margin-top: 20px;
    line-height: 1.2;
    padding: 10px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;

    &.paddingLeft35px {
        padding-left: 35px;
    }

    &:first-child,
    &:first-of-type {
        margin-top: 0;
    }

    &.correct {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #f3fffd;
        border: 1px solid #3c7858;
        border-radius: 3px;
        padding: 10px;

        .fa-check-circle {
            color: #3c7858;
            margin-right: 10px;
            font-size: 1.2rem;
        }

        ~ label {
            padding-top: 5px;
        }
    }

    &.newCorrect {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #eef4f3;
        border: 1px solid #497970;
        border-radius: 3px;
        padding: 10px;

        .fa-check-circle {
            color: #497970;
            margin-right: 10px;
            font-size: 1.2rem;
        }

        ~ label {
            padding-top: 5px;
        }
    }

    .fa-times-circle {
        color: #767676;
        margin-right: 10px;
        font-size: 1.2rem;
    }

    &:nth-child(6n + 1) .discussion-buttons button {
        background: #e48b3a;
        color: #fff;

        &.btn-link,
        &.btn-link:hover,
        &.btn-link:focus {
            color: #1071bc;
            background: none;
        }
    }

    &:nth-child(6n + 2) .discussion-buttons button {
        background: #4e669b;
        color: #fff;

        &.btn-link,
        &.btn-link:hover,
        &.btn-link:focus {
            color: #1071bc;
            background: none;
        }
    }

    &:nth-child(6n + 3) .discussion-buttons button {
        background: #81359f;
        color: #fff;

        &.btn-link,
        &.btn-link:hover,
        &.btn-link:focus {
            color: #1071bc;
            background: none;
        }
    }

    &:nth-child(6n + 4) .discussion-buttons button {
        background: #428459;
        color: #fff;

        &.btn-link,
        &.btn-link:hover,
        &.btn-link:focus {
            color: #1071bc;
            background: none;
        }
    }

    &:nth-child(6n + 5) .discussion-buttons button {
        background: #bfa677;
        color: #fff;

        &.btn-link,
        &.btn-link:hover,
        &.btn-link:focus {
            color: #1071bc;
            background: none;
        }
    }

    &:nth-child(6n + 6) .discussion-buttons button {
        background: #ba4747;
        color: #fff;

        &.btn-link,
        &.btn-link:hover,
        &.btn-link:focus {
            color: #1071bc;
            background: none;
        }
    }
}

.tagCorrect {
    background-color: #3c7858;
    border-radius: 3px;
    color: #fff;
}

.tagNewCorrect {
    background-color: #497970;
    border-radius: 3px;
    color: #fff;
}

.tagIncorrect {
    background-color: #ca3333;
    border-radius: 3px;
    color: #fff;
}

.tagPrimary {
    background-color: #546ea9;
    border-radius: 3px;
    color: #fff;
}

.tagWarning {
    background-color: #985e12;
    border-radius: 3px;
    color: #fff;
}

progress {
    &#progressbar,
    &.progressbar {
        -webkit-appearance: none;
        appearance: none;
        height: 1.2rem;
        border: none;
        background-color: #e5e9f2;
        border: 2px solid #565656;
        overflow: hidden;
        border-radius: 100px;
        flex: 1 1 auto;

        &::-webkit-progress-bar {
            background-color: transparent;
            box-shadow: none;
            border-radius: 100px;
        }

        &::-webkit-progress-value {
            background-color: #761fbb;
            border-radius: 100px;
        }

        &.height2rem {
            height: 2rem;
        }
    }
}

.bg-plain-white {
    background: #fff;
}

.fa-bookmark {
    color: #766f32;
}

fieldset {
    legend {
        margin-bottom: 10px;
        padding-bottom: 0;
        border-bottom: 0;
        width: auto;
        font-weight: bold;
        color: #3c3c43;
    }
}

.question-section-v2 {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    border: solid 1px #d8d8d8;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .question-section-v2-header {
        padding: 16px;
        background: #23356d;
        color: #fff;
        font-weight: bold;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        border-bottom: solid 1px #d8d8d8;
        min-height: 67px;

        h3,
        p {
            font-size: 1rem;
        }

        h3 {
            margin: 0;
        }
    }
}

.vsaTextField {
    width: 200px;
    display: inline-block;
}

.sequencing-div-instructor {
    background: #efefef;
    border: 2px solid #3c3c43;
    border-radius: 8px;
    padding: 16px;

    &.displayGrid {
        padding: 8px;
        display: grid;
        gap: 8px;
        grid-template-columns: 108px 1fr;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .grid-order-1 {
        label {
            text-align: right;
            color: #222;
            background: #fff;
            border-radius: 8px;
            padding: 8px;
            width: 100%;
        }
    }

    label {
        &.correct {
            background: #2d8659;
            color: #fff;
        }

        &.incorrect {
            background: #ca3333;
            color: #fff;
        }
    }

    & > div {
        &:first-child {
            border-radius: 8px;
            padding: 8px;
            background: #fff;
        }
    }

    &.noLabel {
        grid-template-columns: 1fr;
    }

    .matching-answer {
        border-radius: 0;
        background: #fff;
        border: 1px solid #222;
        padding: 8px;

        &:first-of-type {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-of-type {
            border-top: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &:not(:first-of-type):not(:last-of-type) {
            border-top: 0;
        }

        &:only-of-type {
            border-top: 1px solid #222;
        }

        &.categorize-correct {
            background: #e5fff5;
            border-color: #2d8659;

            i {
                font-size: 1.2rem;
                color: #2d8659;
            }
        }

        &.categorize-incorrect {
            background: #e0e0e0;
            border-color: #ca3333;

            i {
                font-size: 1.2rem;
                color: #ca3333;
            }
        }
    }

    @media (max-width: 992px) {
        label:last-child {
            border-radius: 8px;
            padding: 8px;
            width: 100%;
        }
    }
}

.sequencing-div-student {
    background: #efefef;
    border: 2px solid #3c3c43;
    padding: 16px;
    border-radius: 8px;

    &.displayGrid {
        padding: 8px;
        display: grid;
        gap: 8px;
        grid-template-columns: 215px 1fr;
    }

    &:not(:first-child) {
        margin-bottom: 20px;
    }

    label {
        text-align: right;
    }

    & > div {
        &:first-child {
            border-radius: 8px;
            padding: 8px;
            background: #fff;
            border: 2px solid #222;
        }
    }

    &.filled {
        background: #761fbb;
        border-color: #761fbb;

        label,
        dt {
            color: #fff;
        }

        & > div:first-child {
            label {
                color: #222;
            }
        }
    }

    &.disabled {
        background: #efefef;
        border-color: #6c6c6c;

        .form-dropdown {
            &.disabled {
                &:after {
                    color: #fff;
                }
            }

            .form-control {
                &.disabled,
                &:disabled {
                    background: #6c6c6c;
                    border-color: #6c6c6c;
                    color: #fff;
                }
            }
        }

        label,
        dt {
            color: #222;
        }

        & > div {
            &:first-child {
                border-color: #6c6c6c;
            }
        }
    }
}

.categorize-mobile-answer {
    border: 2px solid #222;
    background: #fff;
    border-radius: 8px;
    padding: 6px 12px;

    p {
        font-size: 0.889rem;
    }

    &:not(:first-of-type) {
        margin-top: 10px;

        &.marginTop0 {
            margin-top: 0;
        }
    }
}

.matching-display-question {
    .two-grid {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 1fr;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    label {
        text-align: center;
    }

    .match-options {
        margin: 20px 0;
        cursor: pointer;

        & > .position-relative {
            padding: 10px 30px 10px 10px;
            background-color: #fff;
            border-radius: 8px;
            border: 2px solid #222;
            width: 95.5%;
            font-weight: normal;

            .circle {
                top: calc(50% - 20px);
                right: -20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 2px solid #222;
                background: #fff;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                text-align: center;
                line-height: 0.8;
            }
        }

        &.right {
            display: flex;
            justify-content: end;

            & > .position-relative {
                padding: 10px 10px 10px 30px;

                .circle {
                    left: -20px;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;
        }

        &:focus-visible {
            outline: 4px solid #1941a6;
            outline-offset: 3px;
            border-radius: 3px;
            box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
        }
    }

    .match-linked {
        & > .position-relative {
            background: #761fbb;
            border-color: #761fbb;
            color: #fff;

            .circle {
                background: #3c3c3c;
                border-color: #fff;
                color: #fff;
            }
        }
    }

    .match-selected {
        & > .position-relative {
            background: #3c3c3c;
            border-color: #3c3c3c;
            color: #fff;

            .circle {
                background: #761fbb;
                border-color: #fff;
                color: #fff;
            }
        }
    }

    .match-correct {
        & > .position-relative {
            background: #e5fff5;
            border-color: #2d8659;

            .circle {
                background: #2d8659;
                border-color: #2d8659;
                color: #fff;
            }
        }
    }

    .match-incorrect {
        & > .position-relative {
            background: #efefef;
            border-color: #d04343;

            .circle {
                background: #d04343;
                border-color: #d04343;
                color: #fff;
            }
        }
    }

    .match-isDisabled {
        cursor: not-allowed;

        & > .position-relative {
            background: #efefef;
            border-color: #6c6c6c;
            color: #222;

            .circle {
                background: #efefef;
                border-color: #6c6c6c;
                color: #222;
            }
        }
    }

    .two-grid-more-left {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 0.5fr;
    }

    .categorize-container {
        &.two-grid {
            gap: 0;
        }

        & > div {
            border: 1px solid #222;
            border-radius: 8px;
            padding: 8px;
            background: #fff;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:only-child {
                border-radius: 8px;
                border: 1px solid #222;
            }
        }
    }

    .match-multiple {
        &.two-grid {
            gap: 0;
        }

        & > div {
            border: 1px solid #222;
            border-radius: 8px;
            padding: 8px;

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .match-correct {
            border-radius: 0;

            &:first-of-type {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:last-of-type {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:not(:first-of-type):not(:last-of-type) {
                border-top: 0;
                border-bottom: 0;
            }
        }
    }

    .categorize-button {
        padding: 0 12px;
        background: #fff;
        border: 1px solid #222;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #222;
        font-weight: bold;
        box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);

        button {
            padding: 4px;
        }

        p {
            font-size: 0.889rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 150px;
        }
    }

    .categorize-drag-student {
        background: #efefef;
        border-radius: 8px;
        padding: 8px;
        min-width: 284px;
        border: 1px solid #222;
        position: sticky;
        top: 135px;
    }

    canvas {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }
}

.drop-area {
    border: 1px dashed #6c6c6c;
    border-radius: 8px;
    padding: 8px;
    color: #6c6c6c;
    min-height: 50px;
}

.categorize-container {
    div {
        &.filled {
            background: #761fbb;

            label {
                color: #fff;
            }

            .drop-area {
                border-color: #fff;
                color: #fff;
            }
        }

        &.disabled {
            background: #efefef;
            border-color: #6c6c6c;

            label {
                color: #6c6c6c;
            }

            .drop-area {
                border-color: #6c6c6c;
                color: #6c6c6c;
            }
        }
    }
}

.selected-top {
    border-top: 4px solid blue;
}

.selected-bottom {
    border-bottom: 4px solid blue;
}

.grid-order-1 {
    order: 1;
}

.grid-order-2 {
    order: 2;
}

.multiselect {
    min-height: auto;
    background: linear-gradient(to bottom, #fff 0%, #f5f7f9 100%);
    box-shadow: none;
    border: solid 1px #717171;
    border-radius: 8px;
    overflow: hidden;

    &:focus-within {
        outline: 4px solid #1941a6;
        outline-offset: 3px;
        border-radius: 3px;
        box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
    }

    &__input,
    &__single {
        background: transparent;
    }

    &__tags {
        background: none;
        box-shadow: none;
        border: none;
        border-radius: 0;
        font-size: 0.889rem;
        color: #222;
    }

    &__tag {
        margin-bottom: 0;
    }

    &__option {
        line-height: 1;
        min-height: auto;

        &-wrapper {
            font-size: 0.889rem;
            color: #222;
        }

        &--group {
            font-weight: bold;
            background: none;
        }

        &:after {
            line-height: 36px;
        }

        &--disabled {
            cursor: pointer;
            pointer-events: auto;
        }
    }

    &__select {
        &::before {
            color: #222;
            border-color: #222 transparent transparent transparent;
        }
    }

    &__placeholder {
        font-size: 0.889rem;
        color: #717171;
    }

    &--disabled {
        border-color: #6c6c6c;
        background: #6c6c6c;
        opacity: 1;

        &.hasTooltip {
            pointer-events: inherit;
        }

        .multiselect__select {
            background: none;
            color: #fff;

            &::before {
                color: #fff;
                border-color: #fff transparent transparent transparent;
            }
        }

        .multiselect__placeholder {
            color: #fff;
        }

        .multiselect__tags {
            color: #fff;
        }
    }

    &__content-wrapper {
        position: inherit;
    }
}

.rowGap10px {
    row-gap: 10px;
}

.columnGap10px {
    column-gap: 10px;
}

.vertical6DotsButton {
    padding: 0;
    border-radius: 8px;

    &:hover {
        background: none;
        color: #6c6c6c;
    }

    &.paddingTop5 {
        padding-top: 5px;
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        color: #6c6c6c;
        background: transparent;
    }
}

.list-style-none {
    list-style: none;
}

.categorize-option {
    border-radius: 0;
    background: #fff;
    border: 1px solid #222;
    padding: 8px;

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &:not(:first-of-type):not(:last-of-type) {
        border-top: 0;
    }

    &:only-of-type {
        border-top: 1px solid #222;
    }

    &.categorize-correct {
        background: #e5fff5;
        border-color: #2d8659;

        i {
            color: #2d8659;
        }
    }

    &.categorize-incorrect {
        background: #e0e0e0;
        border-color: #ca3333;

        i {
            color: #ca3333;
        }
    }
}

.two-column-grid-sequence-presentation {
    display: block;
}

@media (min-width: 992px) {
    .two-column-grid-sequence-presentation {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 500px;
    }
}

.enrollment-settings-row {
    border: 1px solid #676767;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;

    & > div {
        padding: 10px;
    }

    &:not(:last-of-type) {
        border-bottom: 0;
    }

    &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .enrollment-settings-actions {
        position: absolute;
        right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-image: -webkit-gradient(linear, right top, left top, from(#ffffff), to(rgba(255, 255, 255, 0.5)));
        background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.5));
        width: 137px;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        height: 100%;
    }
}

.listStyleNone {
    list-style: none;
}

.specializeForManageStudent {
    width: calc(100% - 175px);
}

.height550px {
    max-height: 550px;
    overflow: auto;
}

.dropShadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.thumb-up-toggle {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        outline: none;

        &:before {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            font-family: 'Font Awesome 5 Free';
            display: inline-block;
            content: '\f164';
            color: #222;
            font-size: 1.4rem;
            font-weight: 100;
        }

        &:checked {
            &:before {
                color: #3c7858;
                font-weight: 900;
            }
        }
    }
}

.add-blueBg {
    background: #c1cff2;

    .btn-link {
        color: #0b548c;
    }

    .thumb-up-toggle {
        input {
            &:checked {
                &:before {
                    color: #264c38;
                }
            }
        }
    }

    .statusTagSuccess {
        border-color: #264c38;
        color: #264c38;
    }

    .checkboxField {
        input {
            &:disabled {
                background: #fff;
                border-color: #535353;
                background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23535353%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
            }
        }

        label {
            &.disabled {
                color: #535353;
            }
        }
    }
}

.discussionComment textarea {
    height: auto;
    box-shadow: none;
    padding: 0;
    min-height: 32px;
    max-height: 300px;
    padding: 10px;
    border-radius: 8px;

    &.danger {
        border: 1px solid #ca3333;
    }
}

.questionDiv {
    p {
        font-size: 1.111rem;
    }
}

.correctAnsHint {
    border-radius: 8px;
    overflow: hidden;
    background: #3c7858;
    color: #fff;
    border: 2px solid #3c7858;

    & > div:first-child {
        padding: 8px;
    }

    & > div:last-child {
        padding: 8px;
        background: #fff;
        color: #4b4b4b;
    }
}

.incorrectAnsExplanation {
    border-radius: 8px;
    overflow: hidden;
    background: #ca3333;
    color: #fff;
    border: 2px solid #ca3333;

    & > div:first-child {
        padding: 8px;
    }

    & > div:last-child {
        padding: 8px;
        background: #fff;
        color: #4b4b4b;
    }
}

.darkAnsExplanation {
    border-radius: 8px;
    overflow: hidden;
    background: #222;
    color: #fff;
    border: 2px solid #222;

    & > div:first-child {
        padding: 8px;
    }

    & > div:last-child {
        padding: 8px;
        background: #fff;
        color: #4b4b4b;
    }
}

.instruction_panel {
    position: relative;
    width: 100%;
    display: inline-block;
    border-radius: 2px;
    border: 1px solid #d8d8d8;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    background-color: #ffffff;
    opacity: 1;
    transition: all 0.2s ease;

    .x_header {
        padding: 15px 20px;
        border-bottom: 1px solid #d8d8d8;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        border-radius: 2px;
        background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
        color: #222222;
        font-size: 16px;
        font-weight: bold;

        h1,
        h2 {
            font-size: 1rem;
            margin: 0;
        }

        h3 {
            font-size: 0.88rem;
            margin-bottom: 0;
        }
    }

    .x_content {
        padding: 20px;
        margin-top: 0;
    }
}

.width90Ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
}

.heightFitContent {
    height: fit-content;
}

.widthFitContent {
    width: fit-content;
}

.minWidthFitContent {
    min-width: fit-content;
}

.material-symbols-outlined {
    &.filled {
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
}

.tableCheckBackground {
    background: #e5fff5;
    color: #3b7857;
}

.sidenav-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: rgba(0, 0, 0, 0.3);

    .sidenav-content {
        width: 100%;
        background: #fff;
        height: 100%;
        overflow: hidden;

        .sidenav-header {
            background: #23356d;
            color: #fff;
            padding: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            h2 {
                font-size: 1.111rem;
            }

            .close {
                color: #fff;
            }
        }

        .sidenav-body {
            height: 100%;
            overflow-y: auto;
        }

        .sidenav-footer {
            width: 100%;
            padding: 20px;
            text-align: right;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #fff;
        }
    }
}

@media (min-width: 992px) {
    .sidenav-dialog .sidenav-content {
        width: 448px;
        position: absolute;
        right: 0;
        top: 0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
}

.tabs-sticky {
    position: sticky;
    top: 0;
    z-index: 4;
    background: #f7f8fa;

    .tabs-wrapper {
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
        }

        div[role='tablist'] {
            display: inline-block;
            min-width: 100%;
            white-space: nowrap;
            border-bottom: 3px solid #ddd;
            margin-bottom: 10px;
            margin-top: 10px;
            padding-left: 7px;
            padding-right: 7px;

            button {
                white-space: nowrap;
                box-sizing: border-box;
                border-bottom: 3px solid transparent;
                border-radius: 0;
                margin-bottom: -3px;
                font-size: 0.889rem;
                color: #717171;

                &:hover,
                &:focus {
                    color: #0071be;
                    border-bottom: 3px solid #ddd;
                }

                &.active {
                    color: #222;
                    border-bottom: 3px solid #0071be;
                }
            }
        }
    }
}

.moduleDurationsText {
    p {
        font-size: 0.889rem;
    }
}
