
.card-container {
    padding: 0 20px;
}

.card-body {
    padding-bottom: 20px;
}

.card-container .btn {
    margin-left: 0;
}

.card-body > .col-xs-12:last-child > .form-control-static:last-child {
    margin-bottom: 0;
}

.card-container .mainModulesBtn,
.card-container .mainActivityBtn {
    margin-bottom: 20px;
}

// .subTable {
//     margin-top: 20px;

//     &:first-child {
//         margin-top: 0;
//     }

//     th {
//         position: relative;

//         button {
//             right: 20px;
//             padding: 0;
//             font-size: 0.6rem;
//             border-radius: 2px;

//             .fa.fa-sort {
//                 margin-left: 0;
//             }
//         }
//     }
// }

.btn-grey {
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    background: #fff;
    color: #717171;
    display: inline-block;
    padding: 6px 12px;
    font-size: 0.875em;
    line-height: 1.2;
}

.subTable .fa-ellipsis-v {
    color: #717171;
    font-size: 0.75em;
}

.blueTitle {
    margin-left: 20px;
    color: #0071be;
    display: inline-block;
    font-size: 0.875em;
    text-transform: capitalize;
}

.activityHeaders th {
    padding-top: 6px;
    padding-bottom: 4px;
    font-size: 0.7rem;
    color: #717171;

    i {
        color: #717171;
    }
}

.subContentDetail {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.subContentDetail:last-child {
    margin: 0;
}

.subContentDetail .header {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 20px;
    border-bottom: 1px solid #d8d8d8;
}

.subContentHeader {
    padding: 20px;
    font-weight: bold;
    background: #fbfbfb;
    border-bottom: 1px solid #d8d8d8;
    text-transform: uppercase;
}

.subContentDetails {
    padding: 20px;
}

.table .table.marginBottom20 {
    margin-bottom: 20px;
}
.subContent {
    padding: 0;
    border: 0;
    background: none;
}

.table .subContentTable .table {
    margin-bottom: 20px;
}

.subContentTable:last-child .table {
    margin-bottom: 0;
}

.table .subContentTable tbody .table {
    margin-bottom: 0;
}

ul.nav-pills {
    width: 100%;
    display: flex;
    justify-content: center;
}

ul.nav-pills li {
    background-color: #f2f2f2;
    border: solid 1px #d8d8d8;
    margin-left: 0px;
    border-radius: 4px;
}

ul.nav-pills li a {
    padding: 6px 15px 6px;
}

ul.nav-pills > li:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

ul.nav-pills > li:not(:first-child):not(:last-child) {
    border-radius: 0;
}

ul.nav-pills > li:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

ul.nav-pills > li.active {
    border: solid 1px #0071be;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
}

ul.nav-pills > li.active,
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: black;
    background-color: white;
}

.new-panel {
    .x_content {
        .x_side_bar {
            width: 10%;
            height: auto;
            background: #eff2f6;
            border-right: 1px solid #ddd;
            padding: 10px;

            label {
                padding: 4px 9px;
            }

            .list-group {
                margin-bottom: 0;

                .list-group-item {
                    padding: 8px;
                    line-height: 0.8;
                    background: none;
                    border: none;
                    border-left: 2px solid transparent;
                    color: #333;

                    &:first-child,
                    &:last-child {
                        border-radius: 0;
                    }

                    &.active,
                    &:hover,
                    &:active,
                    &:focus {
                        border-left: 2px solid #ffffff;
                        background: #495060;
                        color: #ffffff;
                    }
                }
            }
        }

        .x_with_side_bar {
            width: calc(100% - 10%);
            padding: 1.5rem;

            &.col-12 {
                width: 100%;
            }
        }
    }
}
.activityTable {
    border-top: 0;
}

.moduleActionHeader {
    width: 70px;
}

.modal-body {
    .row {
        &:not(:first-child) {
            margin-top: 20px;
        }

        .col-md-6 {
            padding-right: 10px;

            &:last-child {
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }
}
.enrollmentSetting {
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }
}
