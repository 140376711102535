
.announcement-card {
    padding: 20px;
    border-radius: 20px;
    max-width: 60%;
    display: flex;
    gap: 16px;
    background: #fff;
    box-shadow: 1px 1px 8px rgba(60, 60, 67, 0.2);

    .icon-container {
        max-width: 64px;
        max-height: 64px;
        border-radius: 10px;
        background-color: #c9eeff;
        color: #222;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        padding: 12px 8px;
    }
}
