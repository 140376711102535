
.breadcrumb {
    padding: 0px;
    background: none;
    margin: 0;

    ol {
        list-style: none;
        padding: 0;
        margin: 0;

        & > li {
            display: inline-block;
            font-size: 1rem;
            color: #222;

            & + li::before {
                content: '>';
                color: #222;
                padding: 0px 5px;
            }

            h1 {
                font-size: 1rem;
                font-weight: normal;
                margin: 0;
                display: inline-block;
            }
        }
    }
}

.top_nav.desktop {
    position: fixed;
    top: 0;
    width: calc(100% - 135px);
    z-index: 1049;
    min-height: 56px;
    background-color: #ffffff;
    /* margin-left: 110px; */
    margin-left: 135px;
    display: flex;
    align-items: center;
}

.top_nav_100.desktop {
    height: 100%;
    background-color: #f7f8fa;
    /* margin-left: 110px; */
    margin-left: 135px;
    padding: 30px 20px 0;
    display: flex;

    &.d-block {
        display: block;
    }
}

.topbar-alert-placement {
    /* margin-left: 110px; */
    margin-left: 135px;
    padding: 0 20px;
}

.top_nav_0.desktop {
    height: 100%;
    background-color: #f7f8fa;
    /* margin-left: 110px; */
    margin-left: 135px;
    padding: 0px 20px;
    display: flex;
}

.top_nav.desktop .nav_title_div_left {
    width: 220px;
    border-right: solid 2px #d8d8d8;
    padding: 0 30px;
    height: 100%;
    font-size: 24px;
    display: flex;
    align-items: center;
}

.top_nav.desktop .nav_title_div_middle {
    /*width:calc(100% - 465px);*/
    flex: 1;
    padding: 10px;
    height: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top_nav.desktop .nav_title_div_right {
    /*width:295px;*/
    border-left: solid 2px #d8d8d8;
    padding: 10px;
    height: auto;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.top_nav.mobile .nav_title_div_right {
    width: 100px;
    padding: 0 25px;
    height: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    line-height: 1;
}
.top_nav.mobile {
    background-color: #273142;
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 998;
    width: 100%;
}

#menu_toggle {
    color: #fff;
    height: 20px;
    display: flex;
    align-items: center;
}

.top_nav.mobile .nav_title_div_left {
    padding: 0 28px;
}

.top_nav.mobile .nav_title_div_middle {
    width: calc(100% - 160px);
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top_nav.desktop .profile_image {
    width: 36px;
    height: 36px;
    border-radius: 30px;
    margin-right: 10px;
}

.top_nav.mobile .profile_image {
    width: 24px;
    height: 24px;
    border-radius: 30px;
    margin-right: 10px;
}

.badge_profile {
    height: 36px;
    width: 36px;
    color: white;
    font-size: 16px;
    font-weight: 900;
    background: #222;
    display: block;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.8;
}
.top_nav.desktop .actual-image {
    background-color: #989b9c;
    height: 36px;
    width: 36px;

    background-position: center;
    background-size: cover;
}

.top_nav.mobile .actual-image {
    background-color: #989b9c;
    height: 24px;
    width: 24px;

    background-position: center;
    background-size: cover;
}

.top_nav.mobile .dropdown.setting {
    color: #fff;
    height: 14px;
}

body.test .defaultTopBar {
    display: none;
}

.defaultTopBar {
    width: 100%;
    z-index: 100;
    min-height: 72px;
}

#logoutConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#logoutConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}

#logoutConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.isStudent {
    .top_nav.mobile {
        background: #0071be;
    }
}

.calculateWithFixedTimer {
    max-width: calc(100% - 440px);
}

@media (max-width: 991px) {
    .defaultTopBar {
        min-height: 50px;
    }

    .top_nav.mobile {
        .nav_title_div_right {
            width: 116px;
        }

        .profile_image {
            width: 36px;
            height: 36px;
            margin-right: 0;
        }
    }
}
