
.topBar {
    margin-bottom: 20px;
}

.topBar .col-xs-12,
.topBar .col-md-4 {
    padding: 0;
}

.subBar {
    margin-bottom: 20px;
}

.subBar > .viewFilter {
    display: flex;
    align-items: center;
    width: auto;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
}

div.displayNo {
    text-align: right;
}
.displayNo {
    text-align: right;
}

.table .table {
    margin-bottom: 0;
}

.table .table td {
    padding: 15px;
}

.table .table td.padding-0 {
    padding: 0;
}

.cardsCon .topBar,
.cardsCon .subBar {
    padding-left: 20px;
}

@media (min-width: 992px) {
    .topBar {
        display: flex;
        justify-content: space-between;
    }

    .subBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .subBar .displayNo {
        margin-left: 20px;
    }

    .searchBox {
        min-width: 145px;
        width: auto;
        max-width: 200px;
    }
}
@media (max-width: 991px) {
    .topBar {
        margin-bottom: 20px;
    }

    .md-upper,
    .md-lower {
        width: 100%;
    }

    .md-upper {
        margin-bottom: 20px;
    }

    .md-lower .form-dropdown {
        min-width: 100px !important;
    }

    .subBar > div {
        width: 100%;
    }
    .subBar > div:first-child {
        margin-bottom: 20px;
    }

    .subBar .displayNo {
        margin-left: 0;
    }
}

.table {
    max-width: inherit;
}

.md-upper {
    display: flex;
    align-items: center;
    font-size: 0.889rem;
    font-weight: bold;
    width: auto;
    line-height: 1;
    border: 1px solid none;
}

.navigation-tab {
    margin-bottom: 20px;
    .links {
        margin-bottom: 0;
    }
}

.x_with_side_bar {
    .md-lower {
        width: 100%;
        justify-content: space-between;
    }
}
