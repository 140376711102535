#viewDropdownMenuButton,
#viewDropdownMenuButton:active {
    color: #222;
    background-color: none;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    border: 2px solid #d8d8d8;
    box-shadow: 2px 2px 2px 0 rgba(202, 202, 202, 0.5);
    min-width: 100px;
    white-space: nowrap;

    &:disabled {
        background-color: #6c6c6c;
        box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
        border: 2px solid #6c6c6c;
        color: #fff;
        background-image: none;
        cursor: not-allowed;
        pointer-events: none;
    }

    &.minWidthFitContent {
        min-width: fit-content;
    }

    &.disabled,
    &[disabled],
    &[aria-disabled='true'] {
        background-color: #6c6c6c;
        box-shadow: -1px 1px 2px 0 rgba(202, 202, 202, 0.5);
        opacity: 1;
        border: 2px solid #6c6c6c;
        color: #fff;
        pointer-events: none;
        cursor: not-allowed;
        background-image: none;

        &.hasToolTip {
            pointer-events: inherit;
        }

        &:hover,
        &:focus,
        &:focus-visible {
            &.hasToolTip {
                pointer-events: inherit;
            }
        }

        &:focus-visible {
            outline: 4px solid #1941a6;
            outline-offset: 3px;
        }
    }
}

.dropdown {
    .dropdown-menu-new {
        margin-top: 8px;
        margin-bottom: 0px;
    }
}

.dropup {
    .dropdown-menu-new {
        margin-top: 0;
        margin-bottom: 8px;
    }
}

.dropdown-menu-new {
    padding: 0;
    min-width: 152px;

    &.withBetaBadge {
        min-width: 215px;
        width: max-content;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        hr {
            margin: 0;
            border-top: 1px solid #d8d8d8;
            padding-bottom: 10px;
        }

        li {
            font-size: 1rem;
            padding: 10px;
            white-space: nowrap;

            &.paddingBottom10 {
                padding-bottom: 10px;
            }

            &.withHover {
                &:hover {
                    background: #d8d8d8;
                    cursor: pointer;
                }
            }

            button {
                width: 100%;
            }

            &:not(:first-child) {
                padding-top: 0;

                &.paddingTop10 {
                    padding-top: 10px;
                }
            }

            a {
                display: block;
                padding: 5px 10px;
                font-weight: normal;
                text-align: left;

                &.default-state,
                &.success-state,
                &.primary-state {
                    background: none;
                    color: #5a5a5a;
                    border: 1px solid #fff;
                    border-radius: 5px;
                    span {
                        color: #5a5a5a;

                        span {
                            border: none;
                            font-size: 22px;
                            margin-right: 8px;
                            vertical-align: top;
                        }
                    }
                }

                &.danger-state {
                    background: none;
                    color: #ca3333;
                    border: 1px solid #fff;
                    border-radius: 5px;

                    span {
                        color: #ca3333;

                        span {
                            border: none;
                            font-size: 22px;
                            margin-right: 8px;
                            vertical-align: top;
                        }
                    }
                }

                &.disabled {
                    color: #c8c8c8;
                    pointer-events: none;
                    cursor: not-allowed;

                    span {
                        color: #c8c8c8;

                        span {
                            border: none;
                            font-size: 22px;
                            margin-right: 8px;
                            vertical-align: top;
                        }
                    }
                }

                &:hover,
                &:focus-visible {
                    background: none;

                    &.primary-state {
                        color: #0071be;
                        border: 1px solid #0071be;
                    }

                    &.primary-state span {
                        color: #0071be;
                    }

                    &.default-state {
                        color: #3c3c43;
                        border: 1px solid #3c3c43;
                    }

                    &.default-state span {
                        color: #3c3c43;
                    }

                    &.success-state {
                        color: #3c7858;
                        border: 1px solid #3c7858;
                    }

                    &.success-state span {
                        color: #3c7858;
                    }

                    &.danger-state {
                        border: 1px solid #ca3333;
                    }

                    &.disabled,
                    &.disabled span {
                        color: #c8c8c8;
                        cursor: not-allowed;
                        pointer-events: none;
                    }
                }

                i {
                    margin-right: 8px;
                    text-decoration: none;
                }

                span:last-child:not(:only-child) {
                    opacity: 0.6;
                }
            }

            button.buttonRole {
                line-height: 0.8;
                padding: 5px 10px;
                font-weight: normal;
                text-align: left;
                justify-content: left;
                border: 1px solid transparent;

                &.default-state,
                &.default-state span,
                &.success-state,
                &.success-state span,
                &.primary-state,
                &.primary-state span {
                    background: none;
                    color: #5a5a5a;
                    border: 1px solid #fff;
                }

                &.danger-state,
                &.danger-state span {
                    background: none;
                    color: #ca3333;
                    border: 1px solid #fff;
                }

                &.disabled,
                &.disabled span,
                &:disabled,
                &:disabled span {
                    color: #c8c8c8;
                    pointer-events: none;
                    cursor: not-allowed;

                    &.hasToolTip {
                        pointer-events: inherit;
                    }
                }

                &:hover {
                    &.primary-state {
                        color: #0071be;
                        border: 1px solid #0071be;
                        border-radius: 5px;
                    }

                    &.primary-state span {
                        color: #0071be;
                    }

                    &.default-state {
                        color: #3c3c43;
                        border: 1px solid #3c3c43;
                        border-radius: 5px;
                    }

                    &.default-state span {
                        color: #3c3c43;
                    }

                    &.success-state {
                        color: #3c7858;
                        border: 1px solid #3c7858;
                        border-radius: 5px;
                    }

                    &.success-state span {
                        color: #3c7858;
                    }

                    &.danger-state {
                        border: 1px solid #ca3333;
                        border-radius: 5px;
                    }

                    &.disabled,
                    &.disabled span,
                    &:disabled,
                    &:disabled span {
                        color: #c8c8c8;
                        cursor: not-allowed;
                        pointer-events: none;
                        border-color: transparent;

                        &.hasToolTip {
                            pointer-events: inherit;
                        }
                    }

                    span {
                        &.betaBadge {
                            background: #fff;
                            color: #0071be;
                            border: 1px solid #0071be;
                        }
                    }
                }

                i {
                    margin-right: 5px;
                    min-width: 24px;
                    text-align: center;
                }

                span {
                    &.betaBadge {
                        background: #fff;
                        color: #0071be;
                        border: 1px solid #0071be;
                    }
                }

                span:last-child:not(:only-child) {
                    opacity: 0.6;
                }

                &.flexSpaceBetween {
                    justify-content: space-between;
                }
            }
        }
    }

    .fontSize-buttons {
        padding: 10px;
        padding-top: 0;

        .primary-state {
            margin-top: 0;
            border: 1px solid transparent;

            &:hover,
            &.active {
                color: #0071be;
                border: 1px solid #0071be;
                border-radius: 5px;
            }
        }

        .btn-default {
            &:hover,
            &.active {
                background: #0071be;
                color: #fff;
                border: 1px solid #0071be;
            }
        }
    }

    &.dropdown-menu-max-content {
        min-width: max-content;
    }
}

.new-custom-search-select {
    #viewDropdownMenuButton,
    #viewDropdownMenuButton:active,
    .btn-dropdown-select,
    .btn-dropdown-select:active {
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f7f9));
        background: linear-gradient(to bottom, #fff 0%, #f5f7f9 100%);
        -webkit-box-shadow: none;
        box-shadow: none;
        border: solid 1px #717171;
        min-width: 150px;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 3px;
        height: auto;
        border-radius: 8px;
        padding: 10px;

        span:first-child {
            text-align: left;
            width: 93%;
            overflow: hidden;
        }

        span:last-child {
            margin-left: 15px;
            color: #3c3c43;
        }
    }

    .btn-dropdown-select {
        padding: 10px;
        min-height: 36px;
    }

    .dropdown-menu-new {
        top: 50px;
        margin-top: 0;
        z-index: 999;
        min-width: 204px;
    }

    .form-group {
        padding: 10px;

        i {
            line-height: 1;
            font-size: 1em;
            margin: 0;
        }

        .form-control-feedback {
            right: 25px;
            top: 38%;
            font-size: 1em;
        }
    }

    ul {
        li {
            button {
                padding: 0;
                justify-content: left;
                font-weight: normal;
                line-height: 1.428571429;
            }

            &:first-child {
                padding-top: 0;

                &.paddingTop10 {
                    padding-top: 10px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 992px) {
    .col-xs-12,
    .col-md-6 {
        .new-custom-search-select {
            width: 100%;

            #viewDropdownMenuButton,
            #viewDropdownMenuButton:active,
            .btn-dropdown-select,
            .btn-dropdown-select:active {
                width: 100%;
                height: 100%;
            }

            &.width150px {
                width: 150px;
            }
        }
    }
}

.filterBox {
    #viewDropdownMenuButton,
    .dropdown-menu {
        border-radius: 0;
        width: 179px;
    }
}
