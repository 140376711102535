
.nav.side-menu {
    background: #495060;
    overflow-y: auto;

    & > li a {
        padding: 12px 8px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 6px solid transparent;
        color: #ffffff;
        background: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-shadow: none;

        .icon-container {
            position: relative;

            .red-dot {
                background: #ffafaf;
                border-radius: 5px;
                position: absolute;
                top: 6px;
                right: 8px;
                color: #495060;
                font-size: 0.667rem;
                padding: 0 4px;
            }
        }

        &:hover {
            background: #ffffff;
            color: #0071be;
            cursor: pointer;
            border-left: 6px solid #0071be30;

            .icon-container {
                .red-dot {
                    background: #ca3333;
                    color: #fff;
                }
            }
        }

        &.active {
            margin-left: 0;
            border-left: 6px solid #0071be;
            border-right: none;
            background: #ffffff;
            color: #0071be;

            .icon-container {
                .red-dot {
                    background: #ca3333;
                    color: #fff;
                }
            }
        }
    }
}

.menu-logo-wrapper {
    padding: 10px;
}

@media (max-width: 991px) {
    .menu-logo-wrapper {
        padding: 10px 25px 10px;
        color: #fff;
        font-size: 24px;
        line-height: 1;
        text-align: center;
    }
}

.help-center {
    position: fixed;
    bottom: 0;
    /* width: 110px; */
    width: 135px;
}

.isStudent {
    .left_col {
        background: #0071be;
        height: 100%;
    }

    .nav {
        &.side-menu {
            background: none;
        }
    }
}
